<template>
    <div :class="$style.Tooltip">
        <div :class="$style.arrow" />

        <slot name="content" />

        <div class="mt-16 flex">
            <slot name="buttons" />
        </div>
    </div>
</template>
  
<script>
export default {
    name: 'ATooltip'
}
</script>

<style lang="scss" module>
.Tooltip {
    @apply relative bg-white p-16 rounded-20 w-[300px] backdrop-blur-[20px];

    .arrow {
        @apply absolute top-0 left-65 w-22 h-22 rounded-tl-3 bg-white rotate-45 transform translate-y-[-50%] backdrop-blur-[20px];
    }
}
</style>  