<template>
    <div class="relative h-full">
        <div class="h-full pb-84 overflow-x-hidden overflow-y-scroll">
            <slot />
        </div>

        <Tabbar :overlay-tabbar="overlayTabbar" />
    </div>
</template>
  
<script>
import Tabbar from './Tabbar.vue'

export default {
    name: 'OLayout',
    components: 
    {
        Tabbar
    },
    props: {
        overlayTabbar: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="scss" module>
</style>  