<template>
    <button v-if="type === 'button'" :disabled="disabled" :class="[
      $style.button, 
      $style[`size-${size}`],
      $style[design],
      { 'callout-bold-text': ( design === 'main' || design === 'secondary' ) && size === 'default' },
      { 'footnote-bold-text': design === 'wallet' || design === 'wallet-secondary' || size === 'sm' },
      { 'color-bg-main': design === 'main' || design === 'wallet' },
      { 'color-bg-mainBg color-text-main': design === 'secondary' },
      {'opacity-50': disabled}
    ]">
        <span v-if="isLoading" class="block">
            <IconMiniLoader :class="[$style.loader, {'h-[21px]': size === 'default'}, {'h-18': size !== 'default'}]" />
        </span>
        <template v-else>
            <IconWallet v-if="leftWallet" class="mr-6" />
            <IconLogout v-if="leftLogout" class="mr-6" />
            <IconLink v-if="leftLink" class="mr-6" />
            {{ text }}
            <IconArrow v-if="rightArrow" class="ml-14" />
            <slot />
        </template>
    </button>

    <router-link v-else-if="type === 'internal'" :to="link" :disabled="disabled" :class="[
      $style.button, 
      $style[`size-${size}`],
      $style[design],
      { 'callout-bold-text': ( design === 'main' || design === 'secondary' ) && size === 'default' },
      { 'footnote-bold-text': design === 'wallet' || design === 'wallet-secondary' || size === 'sm' },
      { 'color-bg-main': design === 'main' || design === 'wallet' },
      { 'color-bg-mainBg color-text-main': design === 'secondary' },
      {'opacity-50': disabled}
    ]">
        <IconWallet v-if="leftWallet" class="mr-6" />
        {{ text }}
        <IconArrow v-if="rightArrow" class="ml-14" />
        <slot />
    </router-link>
</template>
  
<script>
import IconArrow from '../Icon/Arrow.vue'
import IconWallet from '../Icon/Wallet.vue'
import IconLogout from '../Icon/Logout.vue'
import IconLink from '../Icon/Link.vue'
import IconMiniLoader from '../Icon/MiniLoader.vue'

export default {
    name: 'AButton',
    components: 
    {
        IconArrow,
        IconWallet,
        IconLogout,
        IconLink,
        IconMiniLoader
    },
    props: {
        type: {
            type   : String,
            default: "button",
            validator( val )
            {
                return [ "internal", "external", "button" ].includes( val.toLowerCase() )
            }
        },
        link: {
            type: String,
            default: '/'
        },
        text: {
            type: String,
            default: ''
        },
        design: {
            type: String,
            default: "main",
            validator( val )
            {
                return [ "main", "secondary", "wallet", "wallet-secondary", "game", "custom" ].includes( val.toLowerCase() )
            }
        },
        size: {
            type: String,
            default: "default",
            validator( val )
            {
                return [ "default", "sm", "auto" ].includes( val.toLowerCase() )
            }
        },
        rightArrow: {
            type: Boolean,
            default: false
        },
        leftWallet: {
            type: Boolean,
            default: false
        },
        leftLogout: {
            type: Boolean,
            default: false
        },
        leftLink: {
            type: Boolean,
            default: false
        },
        isLoading: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style module>
.button {
    @apply flex justify-center items-center;
}

.size-default {
    @apply px-22 py-16;
}

.size-sm {
    @apply px-12 py-8;
}

.main {
    @apply rounded-10 text-white;
}

.secondary {
    @apply rounded-10;
}

.wallet {
    @apply px-16 py-10 rounded-130 text-white;
}

.wallet-secondary {
    @apply px-16 py-10 rounded-130 text-white;
    background: rgba(255, 255, 255, 0.1);
}

.game {
    @apply rounded-10 text-white;
    background: rgba(255, 255, 255, 0.1);
}

.loader {
    animation: spin 1.5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>  