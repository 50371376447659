<template>
    <div v-if="show" :class="$style.modalOverlay" class="bg-fade dark:bg-darkFade" @click="close">
        <div 
            ref="modalBottom"
            :class="$style.modalContent" 
            class="color-bg-widget"
            :style="{ transform: `translateY(${translateY}px)` }"
            @click.stop
            @touchstart="handleTouchStart" 
            @touchmove="handleTouchMove" 
            @touchend="handleTouchEnd"
        >
            <slot></slot>
        </div>
    </div>
</template>
  
<script>
// import IconClose from '../Icon/Close'

export default {
    name: 'MModalBottom',

    // components: 
    // {
    //     IconClose
    // },

    props: {
        show: {
            type: Boolean,
            required: true,
        },
    },

    data()
    {
        return {
            startY: 0,
            endY: 0,
            translateY: 0,
            threshold: 50
        }
    },

    mounted()
    {
        document.body.style.overflow = "hidden"
    },

    beforeUnmount()
    {
        document.body.style.overflow = ""
    },
    
    methods: {
        close() {
            this.$emit('close')
        },

        handleTouchStart( event )
        {
            this.startY = event.touches[0].clientY
        },

        handleTouchMove( event )
        {
            this.endY = event.touches[0].clientY
            const distance = this.endY - this.startY

            const top = this.$refs.modalBottom.getBoundingClientRect().top

            if ( distance > 0 && this.startY - top <= 36 ) 
            {
                this.translateY = distance
            }
        },

        handleTouchEnd()
        {
            // if ( this.endY - this.startY > 50 )
            // {
            //     this.close()
            // }
            if ( this.translateY > this.threshold ) 
            {
                this.close()
            } 
            else 
            {
                this.translateY = 0;
            }
        }
    },
}
</script>
  
<style lang="scss" module>
.modalOverlay {
    @apply fixed top-0 bottom-0 left-0 right-0 flex justify-center items-end;
    z-index: 99999999;
}

.modalContent {
    @apply relative px-16 pt-16 pb-40 w-full rounded-t-20 overflow-y-scroll;
    // max-height: 100%;
    // max-width: 100%;
    background: radial-gradient(circle, #15569E, #002C5C);
    animation: modalBottom 0.5s;
}

.modalClose {
    @apply absolute top-16 left-[100%] -translate-x-44 w-28 h-28 rounded-full overflow-hidden;
}

.modalIcon {
    @apply absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%];
}

@keyframes modalBottom {
    0% {
        transform: translateY(100%);
    }
    100% {
        transform: translateY(0);
    }
}
</style>