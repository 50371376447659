<template>
    <Layout>
        <div :class="$style.Game">
            <C class="py-16">
                <div class="flex justify-between">
                    <h1 class="large-title-text color-text-primary">
                        {{ $t('game.title') }}
                    </h1>

                    <AButton type="internal" to="/statistics-game" :text="$t('game.button')" design="secondary" size="sm" />
                </div>
            </C>
            <C class="grid grid-cols-2 gap-8">
                <div class="rounded-16 overflow-hidden color-bg-widget" @click="showModalCoin = true">
                    <div class="w-full min-h-100 h-100 overflow-hidden color-bg-main">
                        <img src="@/assets/images/money.png" class="w-full h-auto object-top" />
                    </div>
                    <div class="p-16">
                        <p class="callout-bold-text color-text-primary">
                            {{ $t('game.money.title') }}
                        </p>
                        <p class="mt-2 footnote-bold-text color-text-secondary">
                            {{ $t('game.money.text') }}
                        </p>
                    </div>
                </div>

                <div class="rounded-16 overflow-hidden color-bg-widget" @click="showModalBox = true">
                    <div class="w-full min-h-100 h-100 overflow-hidden color-bg-main">
                        <img src="@/assets/images/box-mini.png" class="w-full h-auto object-top" />
                    </div>
                    <div class="p-16">
                        <p class="callout-bold-text color-text-primary">
                            {{ $t('game.box.title') }}
                        </p>
                        <p class="mt-2 footnote-bold-text color-text-secondary">
                            {{ $t('game.box.text') }}
                        </p>
                    </div>
                </div>
            </C>
            <C class="pt-20 pb-16">
                <h2 class="title2-text color-text-primary">
                    {{ $t('game.subtitle') }}
                </h2>

                <MiniGameRichCell 
                    v-for="(item, i) in events" :key="`event-all-${i}`" 
                    :data="item"
                    @click="showModalEvent = true"
                />
            </C>
        </div>

        <ModalBottom v-if="showModalEvent" :show="showModalEvent" @close="showModalEvent = false">
            <ModalMiniEvent />
        </ModalBottom>

        <ModalBottom v-if="showModalCoin" :show="showModalCoin" @close="showModalCoin = false">
            <ModalGameCoin />
        </ModalBottom>

        <ModalBottom v-if="showModalBox" :show="showModalBox" @close="showModalBox = false">
            <ModalGameBox />
        </ModalBottom>
    </Layout>
</template>
  
<script>
import Layout from './Layout.vue'
import C from '../AppContainer.vue'
import AButton from '../A/Button.vue'
import MiniGameRichCell from '../M/MiniGame/RichCell.vue'
import ModalBottom from '../M/ModalBottom.vue'
import ModalMiniEvent from '../Modal/MiniEvent.vue'
import ModalGameCoin from '../Modal/GameCoin.vue'
import ModalGameBox from '../Modal/GameBox.vue'

export default {
    name: 'OGame',
    components: 
    {
        Layout,
        C,
        AButton,
        MiniGameRichCell,
        ModalBottom,
        ModalMiniEvent,
        ModalGameCoin,
        ModalGameBox
    },
    data()
    {
        return {
            events: [
                {
                    rate: 5,
                    players: 5,
                    state: 'participant',
                    bank: 160,
                    time: '05:09'
                },
                {
                    rate: 10,
                    players: 8,
                    state: null,
                    bank: 50,
                    time: '00:09'
                },
                {
                    rate: 25,
                    players: 10,
                    state: null,
                    bank: 800,
                    time: '05:09'
                },
                {
                    rate: 50,
                    players: 8,
                    state: 'win',
                    bank: 150,
                    time: '09:45'
                },
                {
                    rate: 100,
                    players: 1,
                    state: null,
                    bank: 900,
                    time: '09:09'
                }
            ],

            showModalEvent: false,
            wallet: 'UQBP...LI8k',
            tlt: 9,

            isCliked: false,

            showModalBox: false,
            showModalCoin: false
        }
    },

    mounted()
    {
        if ( window.Telegram.WebApp && window.Telegram.WebApp.BackButton.isVisible )
        {
            window.Telegram.WebApp.BackButton.hide()
        }
    },
}
</script>

<style lang="scss" module>
.Game {
    @apply relative h-full overflow-x-hidden overflow-y-scroll;
    z-index: 1;
}

.buttons {
    @apply flex justify-center items-center;
}

.block {
    @apply grid gap-8;
}

.circle {
    @apply min-w-60 w-60 h-60 border border-dashed border-white rounded-full opacity-20;
}
</style>  