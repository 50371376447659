<template>
    <div :class="[ 
        $style.PropertyCard, $style[design], 
        { 'color-bg-main': background === 'blue' }, 
        { 'color-bg-widget': background !== 'blue' } 
    ]">
        <p :class="classTitle">
            {{ title }}
        </p>

        <div class="flex">
            <p v-if="text" :class="colorText" class="footnote-bold-text">
                {{ text }}
            </p>

            <AButton
                v-if="design === 'horizontal-link' && button"
                :type="typeButton"
                :link="linkButton"
                design="custom"
                size="auto"
                class="pl-16 footnote-bold-text"
                :class="colorButton"
            >
                {{ button }}
                <IconArrow class="ml-10 color-text-secondary" />
            </AButton>

            <ASwitcher v-if="design === 'toggle'" :value="valueSwitch" @update="$emit('updateSwitcher', $event)" />
            <IconCheck v-if="design === 'checkbox'" class="color-text-main" />
        </div>
    </div>
</template>
  
<script>
import AButton from '../A/Button.vue'
import ASwitcher from '../A/Switcher.vue'
import IconArrow from '../Icon/Arrow.vue'
import IconCheck from '../Icon/Check.vue'

export default {
    name: 'PropertyCard',
    components: 
    {
        AButton,
        ASwitcher,
        IconArrow,
        IconCheck
    },
    props: {
        design: {
            type: String,
            default: "default",
            validator( val )
            {
                return [ 'default', 'horizontal', 'horizontal-link', 'toggle', 'checkbox' ].includes( val.toLowerCase() )
            }
        },
        background: {
            type: String,
            default: "default",
            validator( val )
            {
                return [ 'default', 'blue' ].includes( val.toLowerCase() )
            }
        },
        color: {
            type: String,
            default: "default",
            validator( val )
            {
                return [ 'default', 'green' ].includes( val.toLowerCase() )
            }
        },
        colorBtn: {
            type: String,
            default: "default",
            validator( val )
            {
                return [ 'default', 'blue' ].includes( val.toLowerCase() )
            }
        },

        title: {
            type: [String, Number],
            default: ''
        },
        text: {
            type: String,
            default: ''
        },
        button: {
            type: String,
            default: ''
        },
        typeButton: {
            type   : String,
            default: "button",
            validator( val )
            {
                return [ "internal", "external", "button" ].includes( val.toLowerCase() )
            }
        },
        linkButton: {
            type: String,
            default: '/'
        },
        valueSwitch: {
            type: Boolean,
            default: false
        }
    },

    computed:
    {
        classTitle()
        {
            if ( this.background === 'blue' )
            {
                return this.design === 'default' ? 'callout-bold-text text-white' : 'footnote-text text-white' 
            }

            return this.design === 'default' ? 'callout-bold-text color-text-primary' : 'footnote-text color-text-secondary'
        },

        colorText()
        {
            if ( this.background === 'blue' )
            {
                return 'text-white'
            }

            if ( this.color === 'green' )
            {
                return 'color-text-green'
            }

            return this.design !== 'default' ? 'color-text-primary' : 'color-text-secondary'
        },

        colorButton()
        {
            if ( this.colorBtn === 'blue' )
            {
                return 'color-text-main'
            }

            return 'color-text-primary'
        }
    }
}
</script>

<style lang="scss" module>
.PropertyCard {
    @apply flex p-16 rounded-16;

    &.default {
        @apply flex-col;
    }

    &.horizontal {
        @apply justify-between;
    }

    &.horizontal-link {
        @apply justify-between;
    }

    &.toggle {
        @apply justify-between;
    }

    &.checkbox {
        @apply justify-between;
    }
}
</style>  