<template>
    <div :class="$style.MainEvent">
        <h2 class="text-center title1-text color-text-primary">
            {{ $t('modalMainEvent.title') }}
        </h2>

        <div class="mt-16 grid grid-cols-2 gap-8 w-full">
            <Tab 
                :title="$t('modalMainEvent.tab.ton')" 
                :is-active="selectWallet === 'ton'"
                @click="selectWallet = 'ton'"
            />

            <Tab 
                :title="$t('modalMainEvent.tab.tlt')" 
                :is-active="selectWallet === 'tlt'"
                @click="selectWallet = 'tlt'"
            />
        </div>

        <p class="mt-16 text-center subheadline-bold-text color-text-main">
            1 {{ $t('modalMainEvent.ticket') }} = {{ selectWallet === 'ton' ? priceTON : priceTLT }} {{ wallet }}
        </p>

        <div class="mt-20 w-full h-2 rounded-3 color-bg-mainBg" />

        <div class="mt-20 font-sfProRounded">
            <p class="text-42 leading-42 color-text-primary">
                🎟️
                <span class="ml-8">{{ countTickets }}</span>
            </p>
            <p class="mt-8 text-24 text-center color-text-secondary uppercase">
                {{ nameTicket }}
            </p>
        </div>

        <AInputRange :value="countTicketsInput" :min="1" :max="20" class="mt-12" @input="updateSlider" />

        <p class="mt-12 px-20 text-center subheadline-text color-text-secondary">
            {{ $t('modalMainEvent.text') }}
        </p>

        <AButton :text="`${$t('modalMainEvent.button')} ${cost} ${wallet}`" class="mt-20" />

        <div :class="[$style.circle, $style.circleLeft]" class="bg-[#808089] dark:bg-[#05060E]" />
        <div :class="[$style.circle, $style.circleRight]" class="bg-[#808089] dark:bg-[#05060E]" />
    </div>
</template>
  
<script>
import { mapGetters } from 'vuex'
import AButton from '../A/Button.vue'
import Tab from '../A/Tab.vue'
import AInputRange from '../A/InputRange.vue'
import { plural } from '@/lib/utils'

export default {
    name: 'ModalMainEvent',
    components: 
    {
        AButton,
        Tab,
        AInputRange
    },
    data()
    {
        return {
            countTickets: 3,
            countTicketsInput: 3,
            priceTON: 0.1,
            priceTLT: 1000,
            selectWallet: 'ton',
            steps: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20, 30, 40, 50, 60, 70, 80, 90, 100 ]
        }
    },
    computed: {
        ...mapGetters(['language']),

        nameTicket()
        {
            if ( this.language === 'ru' )
            {
                return plural( this.countTickets, [ "билет", "билета", "билетов" ] )
                
            }
            else
            {
                return plural( this.countTickets, [ "ticket", "tickets", "tickets" ] )
            }
        },

        cost()
        {
            const baseCost = this.selectWallet === 'ton' ? this.priceTON : this.priceTLT
            return (this.countTickets * baseCost).toFixed(1).replaceAll( '.0', '' )
        },

        wallet() {
            return this.selectWallet === 'ton' ? 'TON' : 'TLT'
        }
    },

    methods:
    {
        updateSlider(value)
        {
            this.countTicketsInput = +value.target.value
            this.countTickets = this.steps[ this.countTicketsInput - 1 ]
        }
    }
}
</script>

<style lang="scss" module>
.MainEvent {
    @apply relative flex flex-col items-center overflow-hidden;

    .circle {
        @apply w-14 h-14 rounded-full;
    }

    .circleLeft {
        @apply absolute top-[138px] -left-30 translate-x-[-50%];
    }

    .circleRight {
        @apply absolute top-[138px] left-[100%] translate-x-[23px];
    }
}
</style>  