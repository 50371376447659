<template>
    <div v-if="!isLoading" :class="$style.Rules">
        <C :class="$style.wrap">
            <h1 class="pb-4 large-title-text color-text-primary">
                {{ $t('rules.title') }}
            </h1>

            <div :class="$style.block" class="color-bg-widget">
                <h2 class="title2-text color-text-primary">
                    {{ $t('rules.howWork.title') }}
                </h2>

                <p
                    v-for="(item, i) in howWorkList"
                    :key="`howWork-${i}`"
                    class="subheadline-text color-text-secondary"
                >
                    {{ item }}
                </p>
            </div>

            <div :class="$style.block" class="color-bg-widget">
                <h2 class="w-[90%] title2-text color-text-primary">
                    {{ $t('rules.selectWinner.title') }}
                </h2>

                <p class="subheadline-text color-text-secondary">
                    {{ $t('rules.selectWinner.text') }}
                </p>

                <p class="subheadline-text color-text-secondary">
                    <template v-for="(item, i) in selectWinnerList">
                        <span
                            v-if="item.type === 'text'"
                            :key="`selectWinner-${i}`"
                        >
                            {{ item.text }}
                        </span>
                        <a 
                            v-else-if="item.type === 'link'" 
                            :key="`selectWinner-link-${i}`"
                            :href="item.link" 
                            target="_blank"
                            class="mx-2 underline underline-offset-4 decoration-1"
                        >
                            {{ item.text }}
                        </a>
                    </template>
                </p>
            </div>

            <div :class="$style.block" class="color-bg-widget">
                <h2 class="w-[90%] title2-text color-text-primary">
                    {{ $t('rules.prizeFund.title') }}
                </h2>

                <p class="subheadline-text color-text-secondary">
                    {{ $t('rules.prizeFund.subtitle') }}
                </p>

                <div>
                    <h3 class="large-title-bold-text color-text-primary">
                        {{ $t('rules.prizeFund.projectDevelopment.title') }}
                    </h3>

                    <p class="mt-8 subheadline-text color-text-primary">
                        {{ $t('rules.prizeFund.projectDevelopment.subtitle') }}
                    </p>

                    <p 
                        v-for="(item, i) in projectDevelopmentList"
                        :key="`projectDevelopment-${i}`"
                        class="mt-12 flex"
                    >
                        <span class="block mr-12 min-w-34 w-34 text-right subheadline-bold-text color-text-primary">
                            {{ item.percent }}
                        </span>
                        <span class="subheadline-text color-text-secondary">
                            {{ item.text }}
                        </span>
                    </p>
                </div>

                <div>
                    <h3 class="large-title-bold-text color-text-primary">
                        {{ $t('rules.prizeFund.winner.title') }}
                    </h3>

                    <p class="mt-8 subheadline-text color-text-primary">
                        {{ $t('rules.prizeFund.winner.subtitle') }}
                    </p>

                    <div class="grid grid-cols-2 gap-12 mt-12 p-12 rounded-10 color-bg-mainBg">
                        <p 
                            v-for="(item, i) in tableHeader" 
                            :key="`header-${i}`"
                            class="w-[90%] callout-bold-text color-text-main"
                        >
                            {{ item }}
                        </p>
                    </div>
                    <div
                        v-for="(item, i) in tableBody"
                        :key="`row-table-${i}`"
                        class="grid grid-cols-2 gap-12 p-12 rounded-10"
                        :class="{'color-bg-background': (i + 1) % 2 === 0}"
                    >
                        <p class="subheadline-text color-text-primary">
                            {{ item.tickets }}
                        </p>
                        <p class="subheadline-text color-text-primary">
                            {{ item.prize }}
                        </p>
                    </div>
                </div>
            </div>

            <div :class="$style.block" class="color-bg-widget">
                <h2 class="w-[90%] title2-text color-text-primary">
                    {{ $t('rules.win.title') }}
                </h2>

                <p class="subheadline-text color-text-secondary">
                    {{ $t('rules.win.subtitle') }}
                </p>

                <div>
                    <p class="title3-bold-text color-text-primary">
                        {{ $t('rules.win.first.title') }}
                    </p>
                    <p class="mt-8 subheadline-text color-text-secondary">
                        {{ $t('rules.win.first.subtitle') }}
                    </p>
                </div>

                <div>
                    <p class="title3-bold-text color-text-primary">
                        {{ $t('rules.win.second.title') }}
                    </p>
                    <p class="mt-8 subheadline-text color-text-secondary">
                        {{ $t('rules.win.second.subtitle') }}
                    </p>
                </div>

                <p class="title3-text color-text-primary">
                    {{ $t('rules.win.text') }}
                </p>

                <div class="grid grid-cols-2 gap-20">
                    <div>
                        <p class="title3-bold-text color-text-primary">
                            {{ $t('rules.win.third.title') }}
                        </p>
                        <p class="mt-8 subheadline-text color-text-secondary">
                            {{ $t('rules.win.third.subtitle') }}
                        </p>
                    </div>

                    <div>
                        <p class="title3-bold-text color-text-primary">
                            {{ $t('rules.win.fourth.title') }}
                        </p>
                        <p class="mt-8 subheadline-text color-text-secondary">
                            {{ $t('rules.win.fourth.subtitle') }}
                        </p>
                    </div>
                </div>

                <p
                    v-for="(item, i) in winList"
                    :key="`win-${i}`"
                    class="subheadline-text color-text-secondary"
                >
                    {{ item }}
                </p>
            </div>

            <div :class="$style.block" class="color-bg-widget">
                <h2 class="w-[90%] title2-text color-text-primary">
                    {{ $t('rules.example.title') }}
                </h2>

                <p class="subheadline-text color-text-secondary">
                    {{ $t('rules.example.subtitle') }}
                </p>

                <p class="title3-text color-text-primary">
                    {{ $t('rules.example.text') }}
                </p>

                <div>
                    <p class="title3-bold-text color-text-primary">
                        {{ $t('rules.example.first.title') }}
                    </p>
                    <p class="mt-8 subheadline-text color-text-secondary">
                        {{ $t('rules.example.first.subtitle') }}
                    </p>
                </div>

                <div>
                    <p class="title3-bold-text color-text-primary">
                        {{ $t('rules.example.second.title') }}
                    </p>
                    <p class="mt-8 subheadline-text color-text-secondary">
                        {{ $t('rules.example.second.subtitle') }}
                    </p>
                </div>

                <div>
                    <p class="title3-bold-text color-text-primary">
                        {{ $t('rules.example.third.title') }}
                    </p>
                    <p class="mt-8 subheadline-text color-text-secondary">
                        {{ $t('rules.example.third.subtitle') }}
                    </p>
                </div>

                <div>
                    <p class="title3-bold-text color-text-primary">
                        {{ $t('rules.example.fourth.title') }}
                    </p>
                    <p class="mt-8 subheadline-text color-text-secondary">
                        {{ $t('rules.example.fourth.subtitle') }}
                    </p>
                </div>

                <p
                    v-for="(item, i) in exampleList"
                    :key="`example-${i}`"
                    class="subheadline-text color-text-secondary"
                >
                    {{ item }}
                </p>
            </div>
        </C>
    </div>
    <div v-else :class="$style.Rules">
        <Loader />
    </div>
</template>
  
<script>
import C from '../AppContainer.vue'
import Loader from './Loader.vue'

export default {
    name: 'ORules',
    data()
    {
        return {
            isLoading: false
        }
    },
    components: 
    {
        C,
        Loader
    },
    computed: 
    {
        howWorkList() 
        {
            return this.$i18n.messages[this.$i18n.locale].rules.howWork.list
        },

        selectWinnerList() 
        {
            return this.$i18n.messages[this.$i18n.locale].rules.selectWinner.text2
        },

        projectDevelopmentList() 
        {
            return this.$i18n.messages[this.$i18n.locale].rules.prizeFund.projectDevelopment.list
        },

        tableHeader() 
        {
            return this.$i18n.messages[this.$i18n.locale].rules.prizeFund.winner.table.header
        },

        tableBody() 
        {
            return this.$i18n.messages[this.$i18n.locale].rules.prizeFund.winner.table.body
        },

        winList() 
        {
            return this.$i18n.messages[this.$i18n.locale].rules.win.list
        },

        exampleList() 
        {
            return this.$i18n.messages[this.$i18n.locale].rules.example.list
        }
    },
    mounted()
    {
        if ( window.Telegram.WebApp )
        {
            window.Telegram.WebApp.BackButton.show()

            window.Telegram.WebApp.BackButton.onClick(() => {
                this.$router.back()
            })
        }
    }
}
</script>

<style lang="scss" module>
.Rules {
    @apply relative h-full py-16 overflow-x-hidden overflow-y-scroll;

    .wrap {
        @apply grid gap-20 py-16;
    }

    .block {
        @apply grid gap-20 p-16 rounded-16;
    }
}
</style>  