<template>
    <Layout>
        <div :class="$style.Referrals">
            <C 
                v-if="!referrals || !referrals.length" 
                class="flex flex-col justify-between flex-grow"
            >
                <div>
                    <h1 class="large-title-text color-text-primary">
                        {{ $t('referrals.title') }}
                    </h1>

                    <p class="mt-12 body-text color-text-secondary">
                        {{ $t('referrals.subtitle') }}
                    </p>

                    <h2 class="mt-20 title2-text color-text-primary">
                        {{ $t('referrals.rewardsForFriends.title') }}
                    </h2>

                    <div class="mt-12 grid grid-cols-2 gap-8">
                        <PropertyCard
                            v-for="(item, i) in rewardsForFriends"
                            :key="`rewardsForFriends-${i}`" 
                            :title="item.percent"
                            :text="item.text"
                        />
                    </div>

                    <h2 class="mt-20 title2-text color-text-primary">
                        {{ $t('referrals.rewardsForFriendsOfFriends.title') }}
                    </h2>

                    <div class="mt-12 grid grid-cols-2 gap-8">
                        <PropertyCard
                            v-for="(item, i) in rewardsForFriendsOfFriends"
                            :key="`rewardsForFriendsOfFriends-${i}`" 
                            :title="item.percent"
                            :text="item.text"
                        />
                    </div>
                </div>

                <div>
                    <AButton :text="$t('referrals.button.inviteFriends')" class="mt-12 w-full" @click="onShare()" />
                    <AButton 
                        :text="textButtonCopy" 
                        design="secondary"
                        left-link
                        class="mt-12 w-full"
                        @click="copyLink( $t('referrals.button.copiedLink') )"
                    />
                </div>
            </C>

            <template v-else>
                <C class="flex justify-between">
                    <h1 class="large-title-text color-text-primary">
                        {{ referrals.length }} {{ pluralReferrals( referrals.length ) }}
                    </h1>

                    <AButton :text="$t('referrals.button.statistic')" design="secondary" size="sm" @click="$router.push('/statistics-referrals')" />
                </C>

                <div class="mt-20 px-16 flex overflow-x-auto" :class="$style.scroll">
                    <PropertyCard
                        :title="`${totalPaid} TLT`"
                        :text="$t('referrals.totalPaid')"
                        background="blue"
                        class="min-w-max"
                    />
                    <PropertyCard
                        v-for="(item, i) in rewardsForFriends"
                        :key="`rewardsForFriends2-${i}`" 
                        :title="item.percent"
                        :text="item.text"
                        class="ml-8 min-w-max"
                    />
                    <PropertyCard
                        v-for="(item, i) in rewardsForFriendsOfFriends"
                        :key="`rewardsForFriendsOfFriends2-${i}`" 
                        :title="item.percent"
                        :text="item.text"
                        class="ml-8 min-w-max"
                    />
                </div>

                <C class="mt-16 pb-85">
                    <UserCell 
                        v-for="(item, i) in referrals"
                        :key="`referral-${i}`"
                        :data="item"
                    />
                </C>
                <C class="fixed bottom-84 grid grid-cols-2 gap-12 py-16 bg-backgroundTabbar dark:bg-darkBackgroundTabbar">
                    <AButton :text="$t('referrals.button.inviteFriends')" class="px-0" @click="onShare()" />
                    <AButton 
                        :text="textButtonCopy" 
                        design="secondary"
                        left-link
                        @click="copyLink( $t('referrals.button.copiedLinkShort') )"
                    />
                </C>
            </template>
        </div>

        <Modal v-if="showModalReferral" :show="showModalReferral" @close="showModalReferral = false">
            <ModalReferrals @close="showModalReferral = false" />
        </Modal>
    </Layout>
</template>
  
<script>
import Layout from './Layout.vue'
import C from '../AppContainer.vue'
import AButton from '../A/Button.vue'
import Modal from '../M/Modal.vue'
import ModalReferrals from '../Modal/Referrals.vue'
import PropertyCard from '../A/PropertyCard.vue'
import UserCell from '../A/UserCell.vue'
import { plural } from '@/lib/utils'

export default {
    name: 'OReferrals',
    components: 
    {
        Layout,
        C,
        AButton,
        Modal,
        ModalReferrals,
        PropertyCard,
        UserCell
    },
    data()
    {
        return {
            textButtonCopy: null,
            referrals: [],
            // referrals: [
            //     {
            //         name: 'MatveyAnanev',
            //         friends: [
            //             {
            //                 name: 'test'
            //             },
            //             {
            //                 name: 'test'
            //             },
            //             {
            //                 name: 'test'
            //             },
            //             {
            //                 name: 'test'
            //             },
            //             {
            //                 name: 'test'
            //             },
            //             {
            //                 name: 'test'
            //             }
            //         ],
            //         totalPaid: '56 546,5'
            //     },
            //     {
            //         name: 'MatveyAnanev',
            //         friends: [
            //             {
            //                 name: 'test'
            //             },
            //             {
            //                 name: 'test'
            //             },
            //             {
            //                 name: 'test'
            //             }
            //         ],
            //         totalPaid: '56 546,5'
            //     },
            //     {
            //         name: 'MatveyAnanev',
            //         friends: [
            //             {
            //                 name: 'test'
            //             },
            //             {
            //                 name: 'test'
            //             }
            //         ],
            //         totalPaid: '56 546,5'
            //     }
            // ],
            totalPaid: '59 943',
            showModalReferral: false
        }
    },
    computed:
    {
        rewardsForFriends() 
        {
            return this.$i18n.messages[this.$i18n.locale].referrals.rewardsForFriends.list
        },

        rewardsForFriendsOfFriends() 
        {
            return this.$i18n.messages[this.$i18n.locale].referrals.rewardsForFriendsOfFriends.list
        },

        pluralText() 
        {
            return this.$i18n.messages[this.$i18n.locale].referrals.plural;
        }
    },
    mounted()
    {
        this.textButtonCopy = this.referrals && this.referrals.length ? this.$t('referrals.button.copyLinkShort') : this.$t('referrals.button.copyLink')

        if ( window.Telegram.WebApp && window.Telegram.WebApp.BackButton.isVisible )
        {
            window.Telegram.WebApp.BackButton.hide()
        }
    },
    methods:
    {
        copyLink( text )
        {
            // https://t.me/<bot_id>?start=<user_id>
            const initData = window.Telegram.WebApp.initData
            const link = `https://t.me/testbot223rbot?start=${ initData.user.id }`

            navigator.clipboard.writeText( link ).then(() => {
                this.textButtonCopy = text
            })
        },

        onShare()
        {
            if( window.Telegram?.WebApp )
            {
                const initData = window.Telegram.WebApp.initData
                const link = `https://t.me/testbot223rbot?start=${ initData.user.id }`

                window.Telegram.WebApp.shareText(`Приглашаю тебя присоединиться к Tolokon! Вот ссылка: ${link}`)
            }
        },

        pluralReferrals( count )
        {
            return plural( count, this.pluralText )
        }
    }
}
</script>

<style lang="scss" module>
.Referrals {
    @apply relative flex flex-col py-16 h-full overflow-x-hidden overflow-y-scroll;
    z-index: 1;

    .scroll {
        &::-webkit-scrollbar {
            @apply hidden;
        }
    }
}
</style>  