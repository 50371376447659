<template>
    <div :class="$style.Loader">
        <MBlurGradient :class="$style.gradientBlock" />

        <div class="relative z-10 flex justify-center items-center w-[170px] h-[170px]">
            <img src="@/assets/images/logo.png" class="w-[110px] h-[110px]" />
            <IconLoader :class="$style.circle" class="color-text-main" />
        </div>

        <p class="mt-24 text-center callout-text color-text-primary">
            {{ $t('loading.text') }}
        </p>
    </div>
</template>
  
<script>
import IconLoader from '../Icon/Loader.vue'
import MBlurGradient from '../M/BlurGradient.vue'

export default {
    name: 'OLoader',
    components: 
    {
        IconLoader,
        MBlurGradient
    },
}
</script>

<style lang="scss" module>
.Loader {
    @apply flex flex-col justify-center items-center flex-grow;
    z-index: 1;

    .circle {
        @apply absolute top-0 left-0;
        animation: spin 1.5s linear infinite;
    }

    .gradientBlock {
        @apply fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-80%];
        z-index: -1;
    }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>  