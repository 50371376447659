<template>
    <div>
        <div class="flex flex-col items-center">
            <div class="bg-white opacity-20 w-60 h-5 rounded-[2.5px]" />
        </div>

        <div :class="$style.buttons" class="mt-20">
            <AButton :text="wallet" design="wallet-secondary" left-wallet />
            <AWallet design="main" class="ml-8">
                <IconTLT />
                <span class="mx-6">{{ tlt }}</span>
                TLT
            </AWallet>
        </div>

        <p class="mt-20 text-center title2-text text-white">
            9 TLT {{ $t('game.event') }}
        </p>

        <div :class="$style.block" class="mt-20">
            <MRoundTimer date="2024-10-08T12:53:44Z" design="mini-game" />
            <div :class="$style.buttons">
                <AButton :text="$t('main.button.rules')" design="game" size="sm" />
            </div>
        </div>

        <div class="mt-20 -ml-16 flex justify-end overflow-hidden">
            <div :class="$style.circle" class="ml-8" />
            <div :class="$style.circle" class="ml-8" />
            <div :class="$style.circle" class="ml-8" />
            <div :class="$style.circle" class="ml-8" />
            <div :class="$style.circle" class="ml-8" />
            <div :class="$style.circle" class="ml-8" />
            <div :class="$style.circle" class="ml-8" />
        </div>

        <div :class="$style.block" class="mt-16">
            <p class="text-center font-sfProRounded font-semibold tracking-0.4 text-white">
                <span class="text-42 leading-42">
                    <!-- {{ mainEvent.ton }} -->
                        160
                </span>
                <span class="text-24 leading-24">
                    TLT
                </span>
            </p>
            <div class="flex justify-center items-center footnote-bold-text text-white">
                <p>
                    {{ $t('main.bank.text') }}
                </p>
                <div class="mx-4 w-2 h-2 rounded-full bg-primary dark:bg-darkPrimary" />
                <p>
                    8 {{ $t('main.bank.participants') }}
                </p>
            </div>
        </div>

        <AButton v-if="!isCliked" :text="`${$t('game.buttonEvent')} 5 TLT`" class="my-20 w-full" @click="isCliked = true" />
        <div v-else class="my-20 p-16 flex rounded-16 color-bg-widget">
            <p class="mr-12 text-28 leading-34">👍</p>
            <div>
                <p class="font-semibold color-text-primary">
                    {{ $t('game.successMiniEvent.title') }}
                </p>
                <p class="mt-8 text-13 font-medium color-text-secondary">
                    {{ $t('game.successMiniEvent.text') }}
                </p>
            </div>
        </div>
    </div>
</template>
  
<script>
import AButton from '../A/Button.vue'
import AWallet from '../A/Wallet.vue'
import IconTLT from '../Icon/TLT.vue'
import MRoundTimer from '../M/RoundTimer.vue'

export default {
    name: 'ModalMiniEvent',
    components: 
    {
        AButton,
        AWallet,
        IconTLT,
        MRoundTimer
    },
    data()
    {
        return {
            events: [
                {
                    rate: 5,
                    players: 5,
                    state: 'participant',
                    bank: 160,
                    time: '05:09'
                },
                {
                    rate: 10,
                    players: 8,
                    state: null,
                    bank: 50,
                    time: '00:09'
                },
                {
                    rate: 25,
                    players: 10,
                    state: null,
                    bank: 800,
                    time: '05:09'
                },
                {
                    rate: 50,
                    players: 8,
                    state: 'win',
                    bank: 150,
                    time: '09:45'
                },
                {
                    rate: 100,
                    players: 1,
                    state: null,
                    bank: 900,
                    time: '09:09'
                }
            ],

            showModalEvent: false,
            wallet: 'UQBP...LI8k',
            tlt: 9,

            isCliked: false
        }
    }
}
</script>

<style lang="scss" module>
.Game {
    @apply relative h-full;
    z-index: 1;
}

.buttons {
    @apply flex justify-center items-center;
}

.block {
    @apply grid gap-8;
}

.circle {
    @apply min-w-60 w-60 h-60 border border-dashed border-white rounded-full opacity-20;
}
</style>  