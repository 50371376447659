<template>
    <div :class="$style.Distribution">
        <span class="text-48">🎯</span>
        <h2 class="mt-20 title1-text color-text-primary">
            {{ $t('modalDistribution.title') }}
        </h2>

        <div class="mt-20">
            <PropertyCard
                v-for="(item, i) in list"
                :key="`form-distribution-${i}`"
                :title="item.title"
                :text="item.percent"
                design="horizontal"
                class="-mx-16"
                :class="{ 'border-b border-divider dark:border-darkDivider': i !== list.length - 1 }"
            />
        </div>

        <AButton :text="$t('modalDistribution.button')" class="mt-20" @click="$emit('close')" />
    </div>
</template>
  
<script>
import AButton from '../A/Button.vue'
import PropertyCard from '../A/PropertyCard.vue'

export default {
    name: 'ModalDistribution',
    components: 
    {
        AButton,
        PropertyCard
    },
    computed: 
    {
        list() 
        {
            return this.$i18n.messages[this.$i18n.locale].modalDistribution.list;
        },
    }
}
</script>

<style lang="scss" module>
.Distribution {
    @apply flex flex-col items-center;
}
</style>  