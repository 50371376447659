<template>
    <Layout>
        <div :class="$style.Game">
            <C class="pt-16 pb-4">
                <h1 class="large-title-text color-text-primary">
                    {{ $t('statisticsGame.title') }}
                </h1>
            </C>
            <C class="pb-16">
                <MiniGameRichCell 
                    v-for="(item, i) in events" :key="`event-all-${i}`" 
                    :data="item"
                    is-statistics
                    class="pl-0"
                    @click="showModalEvent = true"
                />
            </C>
        </div>
    </Layout>
</template>
  
<script>
import Layout from './Layout.vue'
import C from '../AppContainer.vue'
import MiniGameRichCell from '../M/MiniGame/RichCell.vue'

export default {
    name: 'OGame',
    components: 
    {
        Layout,
        C,
        MiniGameRichCell
    },
    data()
    {
        return {
            events: [
                {
                    rate: 5,
                    players: 5,
                    state: 'participant',
                    bank: 160,
                    time: '05:09',
                    date: '20.09.2025',
                    tickets: 2
                },
                {
                    rate: 10,
                    players: 8,
                    state: null,
                    bank: 50,
                    time: '00:09',
                    date: '20.09.2025',
                    tickets: 1
                },
                {
                    rate: 25,
                    players: 10,
                    state: null,
                    bank: 800,
                    time: '05:09',
                    date: '20.09.2025',
                    tickets: 2
                },
                {
                    rate: 50,
                    players: 8,
                    state: 'win',
                    bank: 150,
                    time: '09:45',
                    date: '20.09.2025',
                    tickets: 1
                },
                {
                    rate: 100,
                    players: 1,
                    state: null,
                    bank: 900,
                    time: '09:09',
                    date: '20.09.2025',
                    tickets: 1
                }
            ],
        }
    },

    mounted()
    {
        if ( window.Telegram.WebApp )
        {
            window.Telegram.WebApp.BackButton.show()

            window.Telegram.WebApp.BackButton.onClick(() => {
                this.$router.back()
            })
        }
    },
}
</script>

<style lang="scss" module>
.StatisticsGame {
    @apply relative h-full overflow-x-hidden overflow-y-scroll;
    z-index: 1;
}

.buttons {
    @apply flex justify-center items-center;
}

.block {
    @apply grid gap-8;
}

.circle {
    @apply min-w-60 w-60 h-60 border border-dashed border-white rounded-full opacity-20;
}
</style>  