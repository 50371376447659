<template>
    <div :class="$style.RichCell">
        <div :class="[$style.icon, {[$style.win]: isWin}]">
            <span v-if="isWin">🎉</span>
            <span v-else>😊</span>
        </div>

        <div class="w-full">
            <div class="flex justify-between mr-12">
                <div>
                    <p class="callout-bold-text color-text-primary">
                        {{ data.date }}
                    </p>

                    <div v-if="isWin" class="mt-2 footnote-bold-text text-green-default dark:text-darkGreen">
                        {{ $t('history.event.win') }} 🎉
                    </div>
                </div>

                <div class="flex justify-center items-center w-18 h-22">
                    <IconArrow class="color-text-secondary" />
                </div>
            </div>

            <div class="flex mt-8 mr-12 pr-18">
                <div class="mr-20">
                    <p class="caption1-bold-text color-text-primary">
                        {{ data.ton }} TON
                    </p>
                    <p class="footnote-text color-text-secondary">
                        {{ $t('history.event.bank') }}
                    </p>
                </div>

                <div>
                    <p class="caption1-bold-text color-text-primary">
                        {{ data.tickets }}
                    </p>
                    <p class="footnote-text color-text-secondary">
                        {{ $t('history.event.ticket') }}
                    </p>
                </div>
            </div>

            <div :class="$style.divider" />
        </div>
    </div>
</template>
  
<script>
import IconArrow from '../Icon/Arrow.vue'

export default {
    name: 'ARichCell',
    components: 
    {
        IconArrow
    },
    props: {
        isWin: {
            type: Boolean,
            default: false
        },
        data: {
            type: Object,
            default: null
        },
    }
}
</script>

<style module>
.RichCell {
    @apply flex pt-16 pl-16;

    .icon {
        @apply flex justify-center items-center mr-10 w-40 h-40 rounded-11 bg-crimson-light text-26;

        &.win {
            @apply bg-green-light-10;
        }
    }

    .divider {
        @apply mt-16 w-full h-1 bg-primary opacity-5 dark:bg-darkPrimary dark:opacity-[8%];
    }
}
</style>  