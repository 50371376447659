<template>
    <svg viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.4 7.5C13.4 8.98067 12.8866 10.4155 11.9473 11.5601C11.0079 12.7047 9.7008 13.4882 8.24858 13.777C6.79636 14.0659 5.2889 13.8423 3.98306 13.1443C2.67722 12.4463 1.6538 11.3171 1.08717 9.94917C0.520542 8.58121 0.445765 7.0591 0.875582 5.64218C1.3054 4.22526 2.21321 3.00121 3.44435 2.17859C4.67549 1.35598 6.15376 0.985686 7.62731 1.13082C9.10085 1.27595 10.4785 1.92752 11.5255 2.97452" stroke="currentColor" stroke-width="1.2" stroke-linecap="round"/>
    </svg>
</template>

<script>
export default {
    name: 'IconMiniLoader'
}
</script>