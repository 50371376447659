<template>
    <div :class="$style.EventInfo">
        <C :class="$style.block">
            <h1 class="subheadline-bold-text color-text-primary text-center">
                {{ $t('eventInfo.title') }} #{{ event.number }}
            </h1>

            <p class="font-sfProRounded text-24 tracking-0.4 text-center color-text-primary">
                <span class="mr-8 text-42">{{ event.prizeFund }}</span> TON
            </p>

            <p class="subheadline-bold-text color-text-primary text-center">
                {{ $t('eventInfo.prizeFund') }}
            </p>
        </C>

        <C :class="$style.wrap">
            <PropertyCard
                :title="event.win.state ? `🎉 ${ $t('eventInfo.win') }` : `😥 ${ $t('eventInfo.lost') }`"
                :text="`${ event.win.ton } TON`"
                design="horizontal"
                color="green"
            />

            <PropertyCard
                :title="$t('eventInfo.referrals')"
                :text="`${ event.referrals } TON`"
                design="horizontal"
            />

            <PropertyCard
                :title="$t('eventInfo.referralsFriends')"
                :text="`${ event.referralsFriends } TON`"
                design="horizontal"
            />

            <PropertyCard
                :title="$t('eventInfo.date')"
                :text="event.date"
                design="horizontal"
            />

            <PropertyCard
                :title="$t('eventInfo.winners')"
                :button="`${ event.winners } ${ $t('eventInfo.winnersButton') }`"
                type-button="internal"
                link-button="/winners"
                color-btn="blue"
                design="horizontal-link"
            />

            <PropertyCard
                :title="`🍀 ${ $t('eventInfo.charity') }`"
                :text="`${ event.charity } TON`"
                design="horizontal"
            />
        </C>
    </div>
</template>
  
<script>
import C from '../AppContainer.vue'
import PropertyCard from '../A/PropertyCard.vue'

export default {
    name: 'OEventInfo',
    components: 
    {
        C,
        PropertyCard
    },
    data()
    {
        return {
            eventWin: {
                number: 10,
                prizeFund: '1 898',
                win: {
                    state: true,
                    ton: '1 650'
                },
                referrals: '165',
                referralsFriends: '16',
                date: '24/02/2024',
                winners: 39,
                charity: 150
            },
            eventLost: {
                number: 10,
                prizeFund: '1 898',
                win: {
                    state: false,
                    ton: '0'
                },
                referrals: '165',
                referralsFriends: '16',
                date: '24/02/2024',
                winners: 39,
                charity: 150
            }
        }
    },
    computed:
    {
        event()
        {
            return this.$route.query.state === 'win' ? this.eventWin : this.eventLost
        }
    }
}
</script>

<style lang="scss" module>
.EventInfo {
    @apply relative grid gap-20 pt-16 h-full overflow-x-hidden overflow-y-scroll;

    .block {
        @apply grid gap-18;
    }

    .wrap {
        @apply py-16 grid gap-8;
    }
}
</style>  