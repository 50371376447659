<template>
    <div :class="[ $style.UserCell, $style[ design ] ]">
        <div :class="$style.image" />

        <div :class="$style.content" class="color-border-divider">
            <div>
                <p class="callout-bold-text color-text-primary">
                    {{ data.name }}
                </p>

                <div v-if="design === 'default' && data.friends" class="flex">
                    <div class="relative max-w-72 h-18" :class="widthBlockImages">
                        <div 
                            v-for="(item, i) in data.friends.slice(0,5)"
                            :key="`friend-${i}`"
                            class="color-border-background"
                            :class="[
                                $style.friend,
                                $style[`friend${i + 1}`],
                                {[$style._first]: i === 0}
                            ]"
                        />
                    </div>

                    <p class="ml-10 footnote-text color-text-secondary">
                        {{ $t('profile.invitation.text') }} {{ data.friends.length }} {{ pluralReferrals( data.friends.length ) }}
                    </p>
                </div>
                <div v-else-if="design === 'date'" class="flex items-center">
                    <p class="footnote-text color-text-secondary">
                        {{ data.date }}
                    </p>
                    <div class="mx-5 w-2 h-2 color-bg-secondary" />
                    <p class="footnote-text color-text-secondary">
                        {{ data.time }}
                    </p>
                </div>
            </div>

            <div v-if="!hiddenPayment" class="ml-auto">
                <p class="callout-bold-text color-text-primary text-right">
                    {{ design === 'date' ? '+' : '' }}{{ data.totalPaid }} TLT
                </p>
                <p v-if="design === 'default'" class="text-right footnote-text color-text-secondary">
                    {{ $t('referrals.payments') }}
                </p>
            </div>
        </div>
    </div>
</template>
  
<script>
import { plural } from '@/lib/utils'

export default {
    name: 'AUserCell',
    props: {
        design: {
            type: String,
            default: "default",
            validator( val )
            {
                return [ "default", "date" ].includes( val.toLowerCase() )
            }
        },
        data: {
            type: Object,
            default: () => { return {} }
        },
        hiddenPayment:
        {
            type: Boolean,
            default: false
        }
    },

    computed:
    {
        pluralText() 
        {
            return this.$i18n.messages[this.$i18n.locale].referrals.plural
        },

        widthBlockImages()
        {
            const count =  this.data.friends ? this.data.friends.length : 0

            switch( count )
            {
                case 1:
                    return 'w-15'
                case 2:
                    return 'w-30'
                case 3:
                    return 'w-44'
                case 4:
                    return 'w-58'
                default:
                    return 'w-72'
            }
        }
    },

    methods:
    {
        pluralReferrals( count )
        {
            return plural( count, this.pluralText )
        }
    }
}
</script>

<style lang="scss" module>
.UserCell {
    @apply flex w-full pt-16;

    .image {
        @apply mr-10 min-w-40 w-40 h-40 rounded-full bg-[#D9D9D9];
    }

    .content {
        @apply flex justify-between pb-16 w-full border-b;
    }

    .friend {
        @apply absolute top-0 w-18 h-18 rounded-full bg-[#D9D9D9] border;

        &._first {
            @apply top-[50%] left-0 translate-y-[-50%] w-15 h-15 border-none;
        }
    }

    .friend2 {
        @apply left-12;
    }

    .friend3 {
        @apply left-26;
    }

    .friend4 {
        @apply left-[39px];
    }

    .friend5 {
        @apply left-52;
    }
}
</style>  