<template>
    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.25001 11.25C2.5925 11.25 1.25 12.5925 1.25 14.25C1.25 14.8125 1.4075 15.345 1.685 15.795C2.2025 16.665 3.15501 17.25 4.25001 17.25C5.34501 17.25 6.29751 16.665 6.81502 15.795C7.09252 15.345 7.25002 14.8125 7.25002 14.25C7.25002 12.5925 5.90751 11.25 4.25001 11.25ZM5.36751 14.7975H4.81251V15.3825C4.81251 15.69 4.55751 15.945 4.25001 15.945C3.94251 15.945 3.68751 15.69 3.68751 15.3825V14.7975H3.13251C2.825 14.7975 2.57 14.5425 2.57 14.235C2.57 13.9275 2.825 13.6725 3.13251 13.6725H3.68751V13.14C3.68751 12.8325 3.94251 12.5775 4.25001 12.5775C4.55751 12.5775 4.81251 12.8325 4.81251 13.14V13.6725H5.36751C5.67501 13.6725 5.93001 13.9275 5.93001 14.235C5.93001 14.5425 5.68251 14.7975 5.36751 14.7975Z" fill="currentColor"/>
        <path d="M16.625 9.375H14.75C13.925 9.375 13.25 10.05 13.25 10.875C13.25 11.7 13.925 12.375 14.75 12.375H16.625C16.835 12.375 17 12.21 17 12V9.75C17 9.54 16.835 9.375 16.625 9.375Z" fill="currentColor"/>
        <path d="M12.8976 4.05012C13.1226 4.26762 12.9351 4.60512 12.6201 4.60512L6.41003 4.59762C6.05003 4.59762 5.87003 4.16262 6.12503 3.90762L7.43754 2.58762C8.54754 1.48512 10.34 1.48512 11.45 2.58762L12.8676 4.02012C12.8751 4.02762 12.8901 4.04262 12.8976 4.05012Z" fill="currentColor"/>
        <path d="M16.9027 13.995C16.4452 15.54 15.1252 16.5 13.3252 16.5H8.45016C8.15766 16.5 7.97016 16.1775 8.09016 15.9075C8.31516 15.3825 8.45766 14.79 8.45766 14.25C8.45766 11.9775 6.60515 10.125 4.33265 10.125C3.76265 10.125 3.20764 10.245 2.69764 10.47C2.42014 10.59 2.08264 10.4025 2.08264 10.1025V9.00001C2.08264 6.96 3.31265 5.535 5.22515 5.295C5.41265 5.265 5.61515 5.25 5.82515 5.25H13.3252C13.5202 5.25 13.7077 5.2575 13.8877 5.2875C15.4027 5.46 16.4977 6.3825 16.9027 7.75501C16.9777 8.00251 16.7977 8.25001 16.5427 8.25001H14.8252C13.1977 8.25001 11.9077 9.73501 12.2602 11.4225C12.5077 12.6525 13.6477 13.5 14.9002 13.5H16.5427C16.8052 13.5 16.9777 13.755 16.9027 13.995Z" fill="currentColor"/>
    </svg>
</template>

<script>
export default {
    name: 'IconWallet'
}
</script>