<template>
    <div 
        :class="[
            $style.widget, 
            {'color-text-primary': design === 'primary'}, 
            {'color-text-main': design === 'main'},
            {'text-white': design === 'games'},
            {'color-bg-widget': design !== 'games'},
            {'color-bg-games': design === 'games'}
        ]" 
        class="footnote-bold-text"
    >
        <slot />
    </div>
</template>
  
<script>
export default {
    name: 'AWallet',
    props: {
        design: {
            type: String,
            default: "primary",
            validator( val )
            {
                return [ "primary", "main", "games" ].includes( val.toLowerCase() )
            }
        },
    }
}
</script>

<style module>
.widget {
    @apply flex justify-center items-center px-18 py-10 w-max rounded-130;
}
</style>  