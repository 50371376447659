<template>
    <svg width="170" height="170" viewBox="0 0 170 170" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M167.5 85C167.5 101.317 162.661 117.267 153.596 130.835C144.531 144.402 131.646 154.976 116.571 161.22C101.496 167.464 84.9085 169.098 68.905 165.915C52.9016 162.732 38.2015 154.874 26.6637 143.336C15.1259 131.798 7.26849 117.098 4.08521 101.095C0.901934 85.0915 2.53571 68.5035 8.77994 53.4286C15.0242 38.3537 25.5984 25.469 39.1655 16.4038C52.7325 7.33854 68.683 2.5 85 2.5" stroke="currentColor" stroke-width="5" stroke-linecap="round"/>
    </svg>
</template>

<script>
export default {
    name: 'IconLoader'
}
</script>