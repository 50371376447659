<template>
    <Layout>
        <C>
            <div :class="$style.StatisticsReferrals">
                <h1 class="large-title-text color-text-primary">
                    {{ $t('statisticsReferrals.title') }}
                </h1>

                <div class="py-16 grid grid-cols-2 gap-8">
                    <PropertyCard
                        :title="totalPaid"
                        :text="$t('statisticsReferrals.totalPaid')"
                    />
                    <PropertyCard
                        :title="totalReferrals"
                        :text="$t('statisticsReferrals.totalReferrals')"
                    />
                </div>

                <UserCell 
                    v-for="(item, i) in referrals" 
                    :key="`referral-${i}`"
                    :data="item"
                    design="date"
                />
            </div>
        </C>
    </Layout>
</template>
  
<script>
import Layout from './Layout.vue'
import C from '../AppContainer.vue'
import PropertyCard from '../A/PropertyCard.vue'
import UserCell from '../A/UserCell.vue'

export default {
    name: 'OStatisticsReferrals',
    components: 
    {
        Layout,
        C,
        PropertyCard,
        UserCell
    },
    data()
    {
        return {
            totalPaid: '59 943',
            totalReferrals: 505,
            referrals: [
                {
                    name: 'MatveyAnanev',
                    referrals: [
                        {
                            name: 'test'
                        }
                    ],
                    totalPaid: '56 546,5',
                    date: 'Сегодня',
                    time: '15:54'
                },
                {
                    name: 'MatveyAnanev',
                    referrals: [
                        {
                            name: 'test'
                        }
                    ],
                    totalPaid: '56 546,5',
                    date: '12.12.2025',
                    time: '15:54'
                },
                {
                    name: 'MatveyAnanev',
                    referrals: [
                        {
                            name: 'test'
                        }
                    ],
                    totalPaid: '56 546,5',
                    date: '12.12.2025',
                    time: '15:54'
                }
            ],
        }
    },

    mounted()
    {
        if ( window.Telegram.WebApp )
        {
            window.Telegram.WebApp.BackButton.show()

            window.Telegram.WebApp.BackButton.onClick(() => {
                this.$router.back()
            })
        }
    },
}
</script>

<style lang="scss" module>
.StatisticsReferrals {
    @apply relative py-16 h-full overflow-x-hidden overflow-y-scroll;
    z-index: 1;
}
</style>  