<template>
    <div v-if="show" :class="$style.modalOverlay" class="bg-fade dark:bg-darkFade" @click="close">
        <div :class="$style.modalContent" class="color-bg-widget" @click.stop>
            <slot></slot>
            
            <button :class="$style.modalClose" @click="close">
                <div class="relative w-full h-full">
                    <IconClose :class="$style.modalIcon" class="color-text-secondary" />
                    <div class="w-full h-full opacity-15 color-bg-secondary" />
                </div>
            </button>
        </div>
    </div>
</template>
  
<script>
import IconClose from '../Icon/Close'

export default {
    name: 'MModal',

    components: 
    {
        IconClose
    },

    props: {
        show: {
            type: Boolean,
            required: true,
        },
    },

    mounted()
    {
        document.body.style.overflow = "hidden"
    },

    beforeUnmount()
    {
        document.body.style.overflow = ""
    },
    
    methods: {
        close() {
            this.$emit('close')
        }
    },
}
</script>
  
<style lang="scss" module>
.modalOverlay {
    @apply fixed top-0 bottom-0 left-0 right-0 flex justify-center items-center;
    z-index: 99999999;
}

.modalContent {
    @apply relative px-30 py-40 w-[350px] rounded-30 overflow-y-scroll;
    max-height: 100%;
    max-width: 100%;
    animation: modal 0.5s;
}

.modalClose {
    @apply absolute top-16 left-[100%] -translate-x-44 w-28 h-28 rounded-full overflow-hidden;
}

.modalIcon {
    @apply absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%];
}

@keyframes modal {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
</style>