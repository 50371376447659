<template>
    <div>
        <div class="flex flex-col items-center">
            <div class="bg-white opacity-20 w-60 h-5 rounded-[2.5px]" />
        </div>

        <div :class="$style.buttons" class="mt-20">
            <AButton :text="wallet" design="wallet-secondary" left-wallet />
            <AWallet design="main" class="ml-8">
                <IconTLT />
                <span class="mx-6">{{ tlt }}</span>
                TLT
            </AWallet>
        </div>

        <p class="mt-20 text-center title1-text text-white">
            {{ $t('gameCoin.title') }}
        </p>

        <p class="mt-8 text-center subheadline-bold-text text-white">
            {{ $t('gameCoin.subtitle') }}
        </p>

        <!-- <AButton :text="$t('main.button.rules')" design="game" size="sm" class="mt-8 mx-auto" /> -->

        <div class="mt-20 relative h-246">
            <div class="relative w-200 h-200 mx-auto">
                <video 
                    ref="videoPlayer" 
                    width="100%"
                    height="auto"
                    preload="auto"
                    playsinline
                    class="absolute top-0 left-0 transition-opacity duration-300"
                    :class="{'opacity-0': isTails}"
                    @ended="onVideoEnd"
                >
                    <source src="coin1.mov" type="video/quicktime" />
                    <source src="coin1.webm" type="video/webm" />
                    Ваш браузер не поддерживает тег видео.
                </video>
                <video 
                    ref="videoPlayer2" 
                    width="100%"
                    height="auto"
                    preload="auto"
                    playsinline
                    class="absolute top-0 left-0 transition-opacity duration-300"
                    :class="{'opacity-0': !isTails}"
                    @ended="onVideoEnd"
                >
                    <source src="coin2.mov" type="video/quicktime" />
                    <source src="coin2.webm" type="video/webm" />
                    Ваш браузер не поддерживает тег видео.
                </video>
                <!-- poster="@/assets/images/box/0001.png" -->
                <p 
                    class="absolute top-[100%] left-[50%] translate-x-[-50%] text-white subheadline-bold-text w-max transition-opacity duration-300"
                    :class="{'opacity-0': !showResult}"
                >
                    {{ isWin ? `${ $t('gameCoin.result') } 5 TLT` : $t('modalLost.title') }}
                </p>
            </div>

            <AWallet :design="selectCoin === 'obverse' ? 'main' : 'games'" class="absolute top-30 left-20" @click="selectCoin = 'obverse'">
                {{ $t('gameCoin.obverse') }}
            </AWallet>

            <AWallet :design="selectCoin === 'reverse' ? 'main' : 'games'" class="absolute bottom-30 right-20" @click="selectCoin = 'reverse'">
                {{ $t('gameCoin.reverse') }}
            </AWallet>
        </div>

        <div class="mt-20 grid grid-cols-5 gap-5">
            <Tab
                v-for="item in rates"
                :key="`rate-${item}`"
                :title="`${ item } TLT`"
                design="game"
                :isActive="item === selectRate"
                @click="selectRate = item"
            />
        </div>

        <AButton :text="`${$t('game.buttonEvent')} ${selectRate} TLT`" :is-loading="isLoading" :disabled="buttonDisabled" class="my-20 w-full" @click="startGame" />
    </div>
</template>
  
<script>
import AButton from '../A/Button.vue'
import AWallet from '../A/Wallet.vue'
import IconTLT from '../Icon/TLT.vue'
import Tab from '../A/Tab.vue'

export default {
    name: 'ModalGameCoin',
    components: 
    {
        AButton,
        AWallet,
        IconTLT,
        Tab
    },
    data()
    {
        return {
            wallet: 'UQBP...LI8k',
            tlt: 9,

            showResult: false,
            selectCoin: 'obverse',

            rates: [ 5, 10, 25, 50, 100 ],
            selectRate: 5,
            isLoading: false,
            isTails: false,
            isWin: false,
            buttonDisabled: false
        }
    },

    mounted()
    {
        if ( this.$refs.videoPlayer )
        {
            this.$refs.videoPlayer.addEventListener('loadedmetadata', () => {
                this.$refs.videoPlayer.play().then(() => {
                    this.$refs.videoPlayer.currentTime = 0.025
                    this.$refs.videoPlayer.pause()
                })
            })
        }

        if ( this.$refs.videoPlayer2 )
        {
            this.$refs.videoPlayer2.addEventListener('loadedmetadata', () => {
                this.$refs.videoPlayer2.play().then(() => {
                    this.$refs.videoPlayer2.currentTime = 0.025
                    this.$refs.videoPlayer2.pause()
                })
            })
        }
    },

    methods:
    {
        startGame()
        {
            this.buttonDisabled = true
            this.isLoading = true

            setTimeout(() => {
                this.isLoading = false
                this.playVideo( true )
            }, 3000);
        },

        playVideo( result )
        {
            if( this.$refs.videoPlayer && ( ( result && this.selectCoin === 'obverse' ) || ( !result && this.selectCoin === 'reverse' ) ) )
            {
                this.isTails = false
                this.showResult = false
                this.$refs.videoPlayer.play()
            }
            else if( this.$refs.videoPlayer2 && ( ( result && this.selectCoin === 'reverse' ) || ( !result && this.selectCoin === 'obverse' ) ) )
            {
                this.isTails = true
                this.showResult = false
                this.$refs.videoPlayer2.play()
            }

            if( result )
            {
                this.isWin = true
            }
        },

        onVideoEnd()
        {
            this.showResult = true
        }
    }
}
</script>

<style lang="scss" module>
.Game {
    @apply relative h-full;
    z-index: 1;
}

.buttons {
    @apply flex justify-center items-center;
}

.block {
    @apply grid gap-8;
}

.circle {
    @apply min-w-60 w-60 h-60 border border-dashed border-white rounded-full opacity-20;
}
</style>  