<template>
    <Layout v-if="!isLoading" :overlay-tabbar="isTooltipWallet">
        <div :class="$style.Main">
            <MBlurGradient :class="$style.bgBlock" />
            <MCircleBG :class="$style.bgBlock" />
            <div v-show="isTooltipWallet" :class="$style.bgWallet" @click="isTooltipWallet = false" />

            <div :class="$style.buttons" class="mb-20">
                <div v-if="!isConnectWallet" class="relative z-20">
                    <AButton 
                        :text="$t('main.button.wallet')" 
                        design="wallet" 
                        left-wallet
                        @click="isTooltipWallet = !isTooltipWallet"
                    />

                    <TooltipConnectWallet 
                        v-if="isTooltipWallet" 
                        class="absolute top-[100%] left-0 translate-y-18"
                        @apply="onConnectWallet"
                        @close="isTooltipWallet = false"
                    />
                </div>

                <AButton 
                    v-else
                    :text="data.tonWallet" 
                    design="wallet-secondary" 
                    left-wallet
                />

                <AWallet class="ml-8">
                    <IconTLT />
                    <span class="mx-6">{{ data.tlt }}</span>
                    TLT
                </AWallet>
            </div>

            <div :class="$style.content">
                <C :class="$style.wrap">
                    <!-- <div :class="$style.buttons">
                        <AButton 
                            :text="$t('main.button.wallet')" 
                            design="wallet" 
                            left-wallet 
                            class="z-20"
                            @click="isTooltipWallet = !isTooltipWallet"
                        />
                        <AWallet class="ml-8">
                            <IconTLT />
                            <span class="mx-6">{{ tlt }}</span>
                            TLT
                        </AWallet>
                    </div> -->

                    <div :class="$style.block">
                        <MRoundTimer :date="dataGame.date" />
                        <!-- <div :class="$style.buttons">
                            <AButton :text="$t('main.button.rules')" design="secondary" size="sm" />
                            <AButton 
                                type="internal"
                                link="/history"
                                :text="$t('main.button.history')" 
                                design="secondary" 
                                size="sm" 
                                class="ml-8" 
                            />
                        </div> -->
                    </div>
                </C>

                <C :class="$style.block" class="mt-20 py-3">
                    <!-- <img src="@/assets/images/logo.png" class="mx-auto w-180 h-180" /> -->
                    <div class="relative w-180 h-180 mx-auto">
                        <video 
                            ref="videoPlayer" 
                            width="100%"
                            height="auto"
                            preload="auto"
                            playsinline
                            autoplay
                            @ended="onVideoEnd"
                        >
                            <source src="coin0.mov" type="video/quicktime" />
                            <source src="coin0.webm" type="video/webm" />
                            Ваш браузер не поддерживает тег видео.
                        </video>
                    </div>
                    <div :class="$style.block">
                        <p class="text-center font-sfProRounded font-semibold tracking-0.4 color-text-primary">
                            <span class="text-42 leading-42">
                                {{ dataGame.bank }}
                            </span>
                            <span class="text-24 leading-24">
                                TON
                            </span>
                        </p>
                        <div class="flex justify-center items-center footnote-bold-text color-text-primary">
                            <p>
                                {{ $t('main.bank.text') }}
                            </p>
                            <div class="mx-4 w-2 h-2 rounded-full bg-primary dark:bg-darkPrimary" />
                            <p>
                                {{ formatNumber( dataGame.players ) }} {{ $t('main.bank.participants') }}
                            </p>
                        </div>
                    </div>
                </C>

                <div :class="$style.wrap" class="mt-20">
                    <C>
                        <AButton 
                            :text="$t('main.button.main')" 
                            class="w-full" 
                            @click="showModalMainEvent = true"
                        />
                    </C>
                    <div class="grid grid-cols-2 gap-8">
                        <APromoCard 
                            :title="$t('main.rules.title')" 
                            :text="$t('main.rules.text')"
                            icon="👩‍🎓"
                            is-blue
                            @click="$router.push('/rules')"
                        />
                        <APromoCard 
                            :title="$t('main.history.title')" 
                            :text="$t('main.history.text')"
                            icon="⏱️"
                            is-green
                            @click="$router.push('/history')"
                        />
                    </div>
                    <!-- <div class="px-16 flex overflow-x-auto" :class="$style.scroll">
                        <APromoCard 
                            :title="$t('main.charity.title')" 
                            :text="$t('main.charity.text')" 
                            is-green 
                            class="mr-8"
                            @click="showModalCharity = true"
                        />
                        <APromoCard 
                            :title="$t('main.distribution.title')" 
                            :text="$t('main.distribution.text')"
                            @click="showModalDistribution = true"
                        />
                    </div> -->
                </div>
            </div>
        </div>

        <Modal v-if="showModalCharity" :show="showModalCharity" @close="showModalCharity = false">
            <ModalCharity @close="showModalCharity = false" />
        </Modal>
        <Modal v-if="showModalDistribution" :show="showModalDistribution" @close="showModalDistribution = false">
            <ModalDistribution @close="showModalDistribution = false" />
        </Modal>
        <Modal v-if="showModalMainEvent" :show="showModalMainEvent" @close="showModalMainEvent = false">
            <ModalMainEvent @close="showModalMainEvent = false" />
        </Modal>
    </Layout>
    <Layout v-else>
        <Loader />
    </Layout>
</template>
  
<script>
import dayjs from "dayjs"
import C from '../AppContainer.vue'
import AButton from '../A/Button.vue'
import AWallet from '../A/Wallet.vue'
import IconTLT from '../Icon/TLT.vue'
import MRoundTimer from '../M/RoundTimer.vue'
import APromoCard from '../A/PromoCard.vue'
import MBlurGradient from '../M/BlurGradient.vue'
import MCircleBG from '../M/CircleBG.vue'
import Layout from './Layout.vue'
import Modal from '../M/Modal.vue'
import ModalCharity from '../Modal/Charity.vue'
import ModalDistribution from '../Modal/Distribution.vue'
import ModalMainEvent from '../Modal/MainEvent.vue'
import TooltipConnectWallet from '../M/TooltipConnectWallet.vue'
import Loader from './Loader.vue'
import { formatNumber } from '@/lib/utils'

export default {
    name: 'OMain',
    components: 
    {
        C,
        AButton,
        AWallet,
        IconTLT,
        MRoundTimer,
        APromoCard,
        MBlurGradient,
        MCircleBG,
        Layout,
        Modal,
        ModalCharity,
        ModalDistribution,
        ModalMainEvent,
        TooltipConnectWallet,
        Loader
    },
    data()
    {
        return {
            showModalCharity: false,
            showModalDistribution: false,
            showModalMainEvent: false,
            isTooltipWallet: false,
            isLoading: false,
            isConnectWallet: false,

            data: {
                id: null,
                avatar: null,
                name: 'Test test',
                tonWallet: 'UQBPHwFxi-W1k8Q7rru8rk6TkvOS9bY7gj_sdf35dLI8k',
                tlt: 9,
                lastLogin: null
            },

            dataGame: {
                id: null,
                date: dayjs().add(1, 'day').unix(),
                bank: 588,
                tickets: 3,
                players: 1485
            }
        }
    },

    mounted()
    {
        if ( window.Telegram.WebApp && window.Telegram.WebApp.BackButton.isVisible )
        {
            window.Telegram.WebApp.BackButton.hide()
        }

        // получить данные о пользователе

        // получить данные о розыгрыше
    },

    methods:
    {
        onVideoEnd()
        {
            if ( this.$refs.videoPlayer )
            {
                this.$refs.videoPlayer.currentTime = 0

                setTimeout(() => {
                    if ( this.$refs.videoPlayer )
                    {
                        this.$refs.videoPlayer.play()
                    }
                }, 2000)
            }
        },

        formatNumber( value )
        {
            return formatNumber( value )
        },

        onConnectWallet()
        {
            this.isConnectWallet = true
            this.isTooltipWallet = false

            // подключить кошелек
        }
    }
}
</script>

<style lang="scss" module>
.Main {
    @apply relative py-16 flex flex-col h-full overflow-x-hidden overflow-y-scroll;
    z-index: 1;

    .bgBlock {
        @apply absolute top-[44.5%] left-[50%] translate-x-[-50%] translate-y-[-50%];
        // translate-y-[-50%]
        // transform: translateY(calc(-50% + 72px));
        z-index: -1;
    }

    .bgCircleBlock {
        @apply absolute top-[44.5%] left-[50%] translate-x-[-50%] translate-y-[-50%];
        z-index: -1;
    }

    .bgWallet {
        @apply absolute top-0 -bottom-85 left-0 right-0 z-10 backdrop-blur-[5px];
        background: rgba(0, 0, 0, 0.3);
    }

    .content {
        @apply relative flex flex-col justify-between flex-grow;
        z-index: 1;
    }

    .wrap {
        @apply grid gap-20;
    }

    .buttons {
        @apply flex justify-center items-center;
    }

    .block {
        @apply grid gap-8;
    }

    .scroll {
        &::-webkit-scrollbar {
            @apply hidden;
        }
    }
}
</style>  