<template>
    <div>
        <div class="flex flex-col items-center">
            <div class="bg-white opacity-20 w-60 h-5 rounded-[2.5px]" />
        </div>

        <div :class="$style.buttons" class="mt-20">
            <AButton :text="wallet" design="wallet-secondary" left-wallet />
            <AWallet design="main" class="ml-8">
                <IconTLT />
                <span class="mx-6">{{ tlt }}</span>
                TLT
            </AWallet>
        </div>

        <p class="mt-20 text-center title1-text text-white">
            {{ $t('gameBox.title') }}
        </p>

        <p class="mt-8 text-center subheadline-bold-text text-white">
            {{ $t('gameBox.subtitle') }}
        </p>

        <!-- <AButton :text="$t('main.button.rules')" design="game" size="sm" class="mt-8 mx-auto" /> -->

        <div class="mt-20 -mx-16">
            <SliderGameBox ref="sliderVideo" />
        </div>

        <AButton :text="`${$t('game.buttonEvent')} 25 TLT`" :is-loading="isLoading" :disabled="buttonDisabled" class="my-20 w-full" @click="openBox" />
    </div>
</template>
  
<script>
import AButton from '../A/Button.vue'
import AWallet from '../A/Wallet.vue'
import IconTLT from '../Icon/TLT.vue'
import SliderGameBox from '../M/SliderGameBox.vue'

export default {
    name: 'ModalGameBox',
    components: 
    {
        AButton,
        AWallet,
        IconTLT,
        SliderGameBox
    },
    data()
    {
        return {
            wallet: 'UQBP...LI8k',
            tlt: 9,

            isCliked: false,
            isLoading: false,
            buttonDisabled: false
        }
    },

    methods:
    {
        playVideo( result )
        {
            this.$refs.sliderVideo.playVideo( result )
        },

        openBox()
        {
            this.buttonDisabled = true
            this.isLoading = true
            this.$refs.sliderVideo.notAllowTouchMode()
            // this.$refs.sliderVideo.onRenderVideo()

            setTimeout(() => {
                this.playVideo( true )

                setTimeout(() => {
                    this.isLoading = false
                }, 800)
            }, 3000)
        }
    }
}
</script>

<style lang="scss" module>
.Game {
    @apply relative h-full;
    z-index: 1;
}

.buttons {
    @apply flex justify-center items-center;
}

.block {
    @apply grid gap-8;
}

.circle {
    @apply min-w-60 w-60 h-60 border border-dashed border-white rounded-full opacity-20;
}
</style>  