<template>
    <div :class="$style.History">
        <C :class="$style.wrap" class="pt-16">
            <h1 class="large-title-text color-text-primary">
                {{ $t('history.title') }}
            </h1>

            <div class="grid grid-cols-2 gap-8">
                <PropertyCard 
                    :title="`${ totalPaid } TON`"
                    :text="$t('history.totalPaid')"
                />

                <PropertyCard 
                    :title="`${ donatedToCharity } TON`"
                    :text="$t('history.donatedToCharity')"
                />
            </div>
        </C>

        <div>
            <C class="grid gap-12">
                <h2 class="title2-text color-text-primary">
                    {{ $t('history.subtitle') }}
                </h2>

                <div class="flex pb-8">
                    <Tab 
                        :title="$t('history.tabs.all')" 
                        :is-active="selectTab === 'all'"
                        class="mr-8"
                        @click="selectTab = 'all'"
                    />
                    <Tab 
                        :title="$t('history.tabs.participated')" 
                        :is-active="selectTab === 'participated'"
                        @click="selectTab = 'participated'"
                    />
                </div>
            </C>

            <template v-if="selectTab === 'all'">
                <ARichCell 
                    v-for="(item, i) in events" :key="`event-all-${i}`" 
                    :data="item" 
                    :is-win="item.state === 'win'"
                    @click="onClick(item)"
                />
            </template>
            <template v-else-if="selectTab === 'participated'">
                <ARichCell 
                    v-for="(item, i) in events2" :key="`event-${i}`" 
                    :data="item" 
                    :is-win="item.state === 'win'"
                    @click="onClick(item)"
                />
            </template>
        </div>
    </div>
</template>
  
<script>
import C from '../AppContainer.vue'
import PropertyCard from '../A/PropertyCard.vue'
import Tab from '../A/Tab.vue'
import ARichCell from '../A/RichCell.vue'

export default {
    name: 'OHistory',
    components: 
    {
        C,
        PropertyCard,
        Tab,
        ARichCell
    },
    data()
    {
        return {
            totalPaid: '52 953',
            donatedToCharity: '5 150',
            events: [
                {
                    state: 'win',
                    date: '20.10.2025',
                    ton: 125,
                    tickets: 24
                },
                {
                    state: 'lost',
                    date: '20.10.2025',
                    ton: 125,
                    tickets: 0
                },
                {
                    state: 'lost',
                    date: '20.10.2025',
                    ton: 125,
                    tickets: 1
                }
            ],
            events2: [
                {
                    state: 'lost',
                    date: '20.10.2025',
                    ton: 125,
                    tickets: 1
                },
                {
                    state: 'win',
                    date: '20.10.2025',
                    ton: 125,
                    tickets: 24
                },
                {
                    state: 'lost',
                    date: '20.10.2025',
                    ton: 125,
                    tickets: 0
                }
            ],
            selectTab: 'all'
        }
    },

    mounted()
    {
        if ( window.Telegram.WebApp )
        {
            window.Telegram.WebApp.BackButton.show()

            window.Telegram.WebApp.BackButton.onClick(() => {
                this.$router.back()
            })
        }
    },

    methods: {
        goPageWin()
        {
            this.$router.push({ path: '/event-info', query: { state: 'win' } });
        },

        goPageLost()
        {
            this.$router.push({ path: '/event-info', query: { state: 'lost' } });
        },

        onClick( event )
        {
            if ( event.state === 'win' )
            {
                this.goPageWin()
            }
            else
            {
                this.goPageLost()
            }
        }
    }
}
</script>

<style lang="scss" module>
.History {
    @apply relative grid gap-20 h-full overflow-x-hidden overflow-y-scroll;

    .wrap {
        @apply grid gap-20;
    }

    .buttons {
        @apply flex justify-center items-center;
    }

    .block {
        @apply grid gap-8;
    }
}
</style>  