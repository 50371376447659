<template>
  <!-- <img alt="Vue logo" src="./assets/logo.png">
  <HelloWorld msg="Welcome to Your Vue.js App"/> -->
  <div class="content" :class="{'dark': isDarkTheme}">
    <!-- <h1>{{ title }}</h1>
    <button @click="sendData">Отправить данные</button>
    <button @click="showModal">Открыть модальное окно</button> -->

    <!-- <div class="mt-10 w-20 h-20 bg-background dark:bg-darkBackground">
      test
    </div> -->
    
    <!-- <Welcome /> -->
    <!-- <Main /> -->
    <!-- <History /> -->
    <!-- <Winners /> -->
    <!-- <EventInfo /> -->
    <!-- <MBox /> -->

    <div class="h-full color-bg-background">
      <router-view />
    </div>
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
// import Welcome from './components/O/Welcome.vue'
// import Main from './components/O/Main.vue'
// import History from './components/O/History.vue'
// import Winners from './components/O/Winners.vue'
// import EventInfo from './components/O/EventInfo.vue'
// import MBox from './components/M/Box.vue'

import { mapMutations, mapGetters } from 'vuex'

export default {
  name: 'App',
  // components: {
  //   Welcome
  // },
  data() {
    return {
      title: 'Telegram Mini App на Vue.js'
    };
  },
  mounted() {
    if (window.Telegram.WebApp) {
      const tg = window.Telegram.WebApp;
      tg.ready()
      tg.expand()
      
      this.applyTelegramTheme()
      this.applyTelegramLang()
    }
  },
  computed: {
    ...mapGetters(['isDarkTheme']),
  },
  methods: {
    ...mapMutations(['setTheme', 'setLanguage']),
    sendData() {
      const tg = window.Telegram.WebApp;
      tg.sendData("Данные для бота");
    },
    showModal()
    {
      const tg = window.Telegram.WebApp;
      tg.showPopup({
        title: "Информация",
        message: "Здесь можно добавить текст, предупреждение или данные.",
        buttons: [{ text: 'Да', action: 'confirm' }, { text: 'Нет', action: 'cancel' }]
      })
    },
    applyTelegramTheme() 
    {
      const savedTheme = localStorage.getItem('theme')

      if ( !savedTheme )
      {
        const tg = window.Telegram.WebApp
        const theme = tg.colorScheme === 'dark' ? 'dark' : 'light'
        this.setTheme( theme )
        localStorage.setItem('theme', theme)
      }
      else
      {
        this.setTheme( savedTheme )
      }
    },
    applyTelegramLang()
    {
      const savedLanguage = localStorage.getItem('language')

      if ( !savedLanguage )
      {
        const tg = window.Telegram.WebApp
        const telegramLanguage = tg.initDataUnsafe?.user?.language_code

        if (telegramLanguage && ( telegramLanguage === 'ru' || telegramLanguage === 'en' )) 
        {
          this.$i18n.locale = telegramLanguage
          this.setLanguage( telegramLanguage )
          localStorage.setItem('language', telegramLanguage)
        }
      }
      else
      {
        this.$i18n.locale = savedLanguage
        this.setLanguage( savedLanguage )
      }
    }
  }
}
</script>

<style>
#app {
  @apply absolute top-0 bottom-0 left-0 right-0 overflow-x-hidden overflow-y-auto flex flex-col font-sfPro text-17 leading-22 -tracking-0.4;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
  /* fixed top-0 bottom-0 left-0 right-0 flex flex-col min- */
}

html {
  font-size: 4px;
  overscroll-behavior: none;
}

body {
  overflow: hidden;
  height: 100vh;
}

.content {
  height: calc(100% + 1px);
}
</style>
