<template>
    <Layout>
        <C>
            <div :class="$style.Tasks">
                <h1 class="large-title-text color-text-primary">
                    {{ $t('tasks.title') }}
                </h1>

                <p class="mt-12 body-text color-text-secondary">
                    {{ $t('tasks.subtitle') }}
                </p>

                <div class="mt-20 p-16 rounded-16 color-bg-widget">
                    <AButton v-if="!isClick" :text="$t('tasks.button.main')" class="w-full" @click="isClick = true" />
                    <template v-else>
                        <div class="flex justify-center">
                            <img src="@/assets/images/wallet.png" class="mr-10 w-[42px] h-[42px]" />
                            <p class="ml-10 font-sfProRounded color-text-primary">
                                <span class="text-42 leading-42">{{ wallet }}</span>
                                <span class="ml-8 text-24">TLT</span>
                            </p>
                        </div>
                        <MTaskTimer :date="date" class="mt-16" />
                    </template>

                    <p class="mt-16 pr-40 footnote-text color-text-secondary">
                        {{ $t('tasks.text') }}
                    </p>
                </div>

                <div class="mt-20 grid gap-8">
                    <div
                        v-for="(item, i) in data"
                        :key="`task-${i}`"
                        class="flex justify-between p-16 rounded-16 color-bg-widget"
                    >
                        <div class="flex">
                            <div 
                                class="flex justify-center items-center min-w-40 w-40 h-40 rounded-11"
                                :class="[
                                    { 'bg-red-light': i === 0 },
                                    { 'bg-blue-light': i === 1 },
                                ]"
                            >
                                {{ item.icon }}
                            </div>
                            <div class="ml-8">
                                <p class="subheadline-bold-text color-text-primary">
                                    {{ item.title }}
                                </p>
                                <p class="footnote-bold-text color-text-secondary">
                                    {{ item.status }}
                                </p>
                            </div>
                        </div>

                        <div class="flex items-center">
                            <p class="mr-8 footnote-bold-text color-text-main w-max">
                                + {{ item.tlt }} TLT
                            </p>

                            <AButton :text="$t('tasks.button.start')" design="secondary" size="sm" @click="showModalTask = true" />
                        </div>
                    </div>
                </div>
            </div>
        </C>

        <Modal v-if="showModalTask" :show="showModalTask" @close="showModalTask = false">
            <ModalTasks @close="showModalTask = false" />
        </Modal>
    </Layout>
</template>
  
<script>
import dayjs from "dayjs"
import Layout from './Layout.vue'
import C from '../AppContainer.vue'
import AButton from '../A/Button.vue'
import MTaskTimer from '../M/TaskTimer.vue'
import Modal from '../M/Modal.vue'
import ModalTasks from '../Modal/Tasks.vue'

export default {
    name: 'OTasks',
    components: 
    {
        Layout,
        C,
        AButton,
        MTaskTimer,
        Modal,
        ModalTasks
    },
    data()
    {
        return {
            initDataRaw: null,
            initData: null,

            isClick: false,
            wallet: 37,
            showModalTask: false,
            date: dayjs().add(1, 'day').unix()
        }
    },
    computed:
    {
        data() 
        {
            return this.$i18n.messages[this.$i18n.locale].tasks.list
        },
    },
    mounted()
    {
        this.initDataRaw = window.Telegram.WebApp.initDataRaw
        this.initData = window.Telegram.WebApp.initData

        console.log('this.initDataRaw', this.initDataRaw)
        console.log('this.initData', this.initData)
    }
}
</script>

<style lang="scss" module>
.Tasks {
    @apply relative py-16 h-full overflow-x-hidden overflow-y-scroll;
    z-index: 1;
}
</style>  