import { createStore } from 'vuex'

export default createStore({
    state: {
        theme: 'light',
        language: 'en'
    },
    mutations: {
        setTheme(state, value) {
            state.theme = value
        },
        setLanguage(state, value) {
            state.language = value
        }
    },
    getters: {
        isDarkTheme: (state) => state.theme === 'dark',
        language: (state) => state.language
    }
})