<template>
    <Layout>
        <div :class="$style.Language">
            <C>
                <h1 class="large-title-text color-text-primary">
                    {{ $t('language.title') }}
                </h1>

                <div class="py-16 grid gap-8">
                    <PropertyCard 
                        :title="$t('language.en')"
                        :design="language === 'en' ? 'checkbox' : 'horizontal'"
                        @click="onChangeLanguage('en')"
                    />

                    <PropertyCard 
                        :title="$t('language.ru')"
                        :design="language === 'ru' ? 'checkbox' : 'horizontal'"
                        @click="onChangeLanguage('ru')"
                    />
                </div>
            </C>
        </div>
    </Layout>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import Layout from './Layout.vue'
import C from '../AppContainer.vue'
import PropertyCard from '../A/PropertyCard.vue'

export default {
    name: 'OLanguage',
    components: 
    {
        Layout,
        C,
        PropertyCard
    },
    computed:
    {
        ...mapGetters(['language']),
    },
    mounted()
    {
        if ( window.Telegram.WebApp )
        {
            window.Telegram.WebApp.BackButton.show()

            window.Telegram.WebApp.BackButton.onClick(() => {
                this.$router.back()
            })
        }
    },
    methods:
    {
        ...mapMutations(['setLanguage']),

        onChangeLanguage(value)
        {
            this.$i18n.locale = value
            this.setLanguage( value )
            localStorage.setItem('language', value)
        }
    }
}
</script>

<style lang="scss" module>
.Language {
    @apply relative py-16 h-full overflow-x-hidden overflow-y-scroll;
}
</style>  