<template>
    <div :class="$style.RichCell">
        <div :class="[$style.icon, {[$style.lost] : isStatistics && data.state !== 'win' }, , {[$style.win] : isStatistics && data.state === 'win' }]">
            <span v-if="!isStatistics">{{ icons[Math.floor(Math.random() * icons.length)] }}</span>
            <span v-else-if="data.state === 'win'">🎉</span>
            <span v-else>😊</span>
        </div>

        <div class="w-full">
            <div class="flex justify-between mr-12">
                <div>
                    <p class="callout-bold-text color-text-primary">
                        {{ data.rate }} TLT {{ $t('game.event') }}
                    </p>

                    <div class="mt-2 flex items-center footnote-bold-text">
                        <div v-if="!isStatistics" class="color-text-secondary">
                            {{ data.players }} {{ pluralPlayers }} {{ $t('game.from') }} 10
                        </div>
                        <div v-else class="color-text-secondary">
                            {{ data.date }}
                        </div>
                        <div v-if="data.state && ( !isStatistics || ( isStatistics && data.state === 'win' ) )" class="mx-6 w-2 h-2 color-bg-secondary" />
                        <div v-if="data.state === 'participant' && !isStatistics" class="text-green-default dark:text-darkGreen">
                            {{ $t('game.state.participant') }} 👍
                        </div>
                        <div v-if="data.state === 'win'" class="text-green-default dark:text-darkGreen">
                            {{ $t('game.state.win') }} 🎉
                        </div>
                    </div>
                </div>

                <div v-if="!isStatistics" class="flex justify-center items-center w-18 h-22">
                    <IconArrow class="color-text-secondary" />
                </div>
            </div>

            <div class="flex mt-8 mr-12 pr-18">
                <div v-if="!isStatistics" class="mr-16 min-w-[70px]">
                    <p class="caption1-bold-text color-text-primary">
                        {{ data.rate }} TLT
                    </p>
                    <p class="footnote-text color-text-secondary">
                        {{ $t('game.rate') }}
                    </p>
                </div>

                <div class="mr-16 min-w-[70px]">
                    <p class="caption1-bold-text color-text-primary">
                        {{ data.bank }} TLT
                    </p>
                    <p class="footnote-text color-text-secondary">
                        {{ $t('game.bank') }}
                    </p>
                </div>

                <div v-if="isStatistics" class="mr-16 min-w-[70px]">
                    <p class="caption1-bold-text color-text-primary">
                        {{ data.tickets }}
                    </p>
                    <p class="footnote-text color-text-secondary">
                        {{ $t('game.tickets') }}
                    </p>
                </div>

                <div v-if="!isStatistics">
                    <p class="caption1-bold-text">
                        <span v-if="data.time[0] === '0' && data.time[1] === '0'" class="text-red-default dark:text-darkRed">{{ data.time }}</span>
                        <span v-else class="color-text-primary">{{ data.time }}</span>
                    </p>
                    <p class="footnote-text color-text-secondary">
                        {{ $t('game.round') }}
                    </p>
                </div>
                <div v-else>
                    <p class="caption1-bold-text color-text-primary">
                        {{ data.prize }} TON
                    </p>
                    <p class="footnote-text color-text-secondary">
                        {{ $t('game.prize') }}
                    </p>
                </div>
            </div>

            <div :class="$style.divider" />
        </div>
    </div>
</template>
  
<script>
import IconArrow from '../../Icon/Arrow.vue'
import { plural } from '@/lib/utils'

export default {
    name: 'MMiniGameRichCell',
    components: 
    {
        IconArrow
    },
    props: {
        data: {
            type: Object,
            default: null
        },
        isStatistics: {
            type: Boolean,
            default: false
        }
    },
    data()
    {
        return {
            icons: ['🤑', '🍾', '👰‍♀️', '🍕', '🎲']
        }
    },
    computed:
    {
        pluralText() 
        {
            return this.$i18n.messages[this.$i18n.locale].game.players;
        },

        pluralPlayers()
        {
            return plural( this.data.players, this.pluralText )
        }
    }
}
</script>

<style module>
.RichCell {
    @apply flex pt-16 pl-16;

    .icon {
        @apply flex justify-center items-center mr-10 min-w-40 w-40 h-40 rounded-11 text-26;
        background: rgba(0, 122, 255, 0.1);

        &.win {
            @apply bg-green-light-10;
        }

        &.lost {
            @apply bg-red-light;
        }
    }

    .divider {
        @apply mt-16 w-full h-1 bg-primary opacity-5 dark:bg-darkPrimary dark:opacity-[8%];
    }
}
</style>  