import { createI18n } from 'vue-i18n';

// Определяем переводы для русского и английского
const messages = {
  en: {
    welcome: {
        headline: 'Hello!',
        title: 'Welcome to Toloton!',
        subtitle: 'Toloton is the first fully transparent lottery on the TON blockchain',
        list: [ 'Each participant contributes $1', 'The winner is determined randomly once a day and takes 80% of the pot.'],
        text: 'Our smart contract has been fully audited by the TON Foundation. We determine the winner using the ECVRF mechanism, which guarantees random selection of the winner.',
        button: {
            main: 'To be continued',
            secondary: 'How is the bank distributed?'
        }
    },
    main: {
        roundTime: 'Until the end round',
        bank: {
            text: 'Collected in bank',
            participants: 'players'
        },
        charity: {
            title: 'Charity',
            text: `We're helping`
        },
        distribution: {
            title: 'Distribution',
            text: `It's all transparent`
        },
        rules: {
            title: 'Rules',
            text: 'game rules'
        },
        history: {
            title: 'History',
            text: 'raffle history'
        },
        button: {
            wallet: 'Connect Wallet',
            rules: 'Game rules',
            history: 'Raffle History',
            main: 'Participate for TON or TLT'
        },
        connectWallet: {
            title: 'Connect wallet',
            text: 'Plug in your wallet so we know where to send your winnings.',
            button: {
                next: 'Next',
                skip: 'Skip'
            }
        }
    },
    history: {
        title: 'Raffle History',
        subtitle: 'History',
        totalPaid: 'Total raffled off',
        donatedToCharity: 'Sent to charity',
        tabs: {
            all: 'All games',
            participated: `Games I've participated in`
        },
        event: {
            win: 'You winner',
            bank: 'Bank',
            ticket: 'Your tickets'
        }
    },
    winners: {
        title: 'Winners',
        button: 'View'
    },
    eventInfo: {
        title: 'Lettery',
        prizeFund: 'Prize fund',
        win: 'Your winnings',
        lost: `You didn't win`,
        referrals: 'Friends',
        referralsFriends: `Friend's friend`,
        date: 'Lottery date',
        winners: 'Winners',
        winnersButton: 'winners',
        charity: 'Sent it to charity'
    },
    game: {
        title: 'Games',
        button: 'Games statistic',
        money: {
            title: 'The Coin Game',
            text: 'The classic game of heads or tails'
        },
        box: {
            title: 'The Coin Game',
            text: 'The classic game of heads or tails'
        },
        subtitle: 'TLT Lottery',
        event: 'lottery',
        players: ['player', 'players', 'players'],
        from: 'out of',
        state: {
            participant: 'You participate',
            win: 'You winner'
        },
        rate: 'Bet',
        bank: 'Bank',
        round: 'Until the end round',
        tickets: 'Tickets',
        prize: 'Prize',
        buttonEvent: 'Participate for',

        successMiniEvent: {
            title: `The bet's on!`,
            text: 'You are entered into a drawing. Wait for the results - good luck!'
        }
    },
    gameBox: {
        title: 'Chest game',
        subtitle: 'Guess which box the coin is in',
        result: 'Your winnings',
        box: 'Secret box'
    },
    gameCoin: {
        title: 'The Coin Game',
        subtitle: 'The classic game of heads or tails',
        result: 'Your winnings',
        obverse: 'Obverse',
        reverse: 'Reverse'
    },
    profile: {
        title: 'Profile',
        invitation: {
            text: 'Invited',
            plural: ['friend', 'friends', 'friends']
        },
        wallet: 'Wallet',
        theme: 'Dark theme',
        language: 'Language',
        button: {
            main: 'Buy TLT by stars',
            logout: 'Logout'
        }
    },
    referrals: {
        title: 'Friends',
        subtitle: 'Involve your friends and win together!',
        rewardsForFriends: {
            title: 'Rewards for friends',
            list: [
                {
                    percent: '2.6%',
                    text: `With every friend's bet`
                },
                {
                    percent: '1.6%',
                    text: `From a friend's winnings`
                }
            ]
        },
        rewardsForFriendsOfFriends: {
            title: 'Rewards for friends of friends',
            list: [
                {
                    percent: '0.8%',
                    text: `To your friend's friend`
                },
                {
                    percent: '0.4%',
                    text: `To your friend's friend winnings`
                }
            ]
        },
        button: {
            inviteFriends: 'Invite friends',
            copyLink: 'Copy the invitation link',
            copiedLink: 'Link copied',
            statistic: 'Accrual statistics',
            copyLinkShort: 'Copy link',
            copiedLinkShort: 'Copied',
        },
        plural: ['Friend', 'Friends', 'Friends'],
        totalPaid: 'Your total paid',
        payments: 'Your profit'
    },
    statisticsReferrals: {
        title: 'Accrual statistics',
        totalPaid: 'Your total paid',
        totalReferrals: 'Your total friends',
    },
    tasks: {
        title: 'Earn',
        subtitle: 'Complete tasks and get tlt',
        button: {
            main: 'Get daily TLT',
            start: 'Start'
        },
        text: 'You have the opportunity to receive a random amount of TLT once a day. The received TLT will burn down to 0 within 24 hours.',
        list: [
            {
                icon: '🎲',
                title: 'See ads',
                status: '0/20',
                tlt: '1 000'
            },
            {
                icon: '💬',
                title: 'Subscribe our Telegram channel',
                status: null,
                tlt: '1 000'
            }
        ]
    },
    language: {
        title: 'Select language',
        en: 'English',
        ru: 'Russia'
    },
    statisticsGame: {
        title: 'Statistic'
    },

    modalMainEvent: {
        title: 'Number of tickets',
        tab: {
            ton: 'Buy by TON',
            tlt: 'Buy by TLT'
        },
        ticket: 'ticket',
        text: 'More tickets, more chance to win',
        button: 'Buy for'
    },
    modalWin: {
        title: 'Congratulations, you are a winner!',
        subtitle: 'The prize has already been sent to your wallet',
        text: 'Thank you for participating in our raffle and doing good with us. Congratulations and good luck in the next drawings.',
        charity: '1% of your winnings are donated to charity',
        button: {
            transaction: 'View Transaction',
            close: 'Confirm'
        }
    },
    modalLost: {
        title: 'Thanks for participating!',
        subtitle: 'Thank you for participating in the raffle',
        text: 'Although you were not lucky enough to be entered in this drawing, thank you for participating in the raffle',
        button: 'Confirm'
    },
    modalCharity: {
        title: 'Charity',
        text: [
            'We believe that every raffle can bring not only joy to the winners, but also benefit those in need of support',
            '1% of each drawing goes to the fund to support charitable projects. By participating, you are helping those in need'
        ],
        button: 'Confirm'
    },
    modalDistribution: {
        title: '80% for you, 20% for us',
        list: [
            {
                title: 'To support and develop the project team',
                percent: '5%'
            },
            {
                title: 'To investors who supported the project in its early stages',
                percent: '5%'
            },
            {
                title: 'For promotion and advertising',
                percent: '4%'
            },
            {
                title: 'On referral program if there are invited participants',
                percent: '3,75%'
            },
            {
                title: 'For charity',
                percent: '1%'
            },
            {
                title: 'For Ton Foundation to support the development of the TON ecosystem',
                percent: '1%'
            }
        ],
        button: 'Confirm'
    },
    modalReferrals: {
        title: 'Thank you for your active participation and referring friends',
        text: 'Referral payment',
        button: 'Confirm'
    },
    modalTasks: {
        title: 'Congrats!',
        button: 'Confirm'
    },
    loading: {
        text: 'Loading...'
    },
    rules: {
        title: 'Game rules',
        howWork: {
            title: 'Как работает ToloTon?',
            list: [
                'Каждый день в 12:00 UTC в ToloTon проходит главное событие — Main Event, где участники могут выиграть TON. Победители выбираются с использованием смарт-контракта и передовой технологии случайного выбора — Elliptic Curve Verifiable Random Function (ECVRF). Этот алгоритм полностью прозрачен и верифицируем, что гарантирует честность каждого розыгрыша.',
                'Для участия в главном розыгрыше достаточно приобрести хотя бы один билет. Стоимость билета — 0.1 TON или 1000 TLT — это наша внутренняя валюта, которая напрямую привязана к TON (1 TON = 10 000 TLT).',
                'Таким образом, участники могут сразу купить билет за 0.1 TON и принять участие в розыгрыше. А также получают уникальную возможность заработать TLT через выполнение заданий, игры и другие активности в приложении, и использовать их для участия в розыгрыше. ',
                'Все призы выплачиваются в TON, что даёт возможность выигрывать TON каждый день без каких-либо вложений.'
            ]
        },
        selectWinner: {
            title: 'Честность и прозрачность выбора победителей',
            list: [
                'Процесс выбора победителей строится на работе смарт-контракта, который использует криптографическую функцию ECVRF для создания случайного результата. Это обеспечивает полную независимость и объективность розыгрыша, гарантируя, что ни один из участников не может повлиять на исход. Любой желающий может проверить результаты лотереи, обратившись к открытому смарт-контракту.',
                'Мы основали наш механизм на научных стандартах, подтвержденных следующими исследованиями: RFC 9381 и RFC 6979. Эти источники позволяют глубже понять принципы работы ECVRF и его роль в обеспечении безопасности и справедливости.'
            ]
        },
        prizeFund: {
            title: 'Распределение призового фонда',
            subtitle: 'Призовой фонд в ToloTon распределяется таким образом, чтобы максимально вовлечь участников и вознаградить их усилия.',
            projectDevelopment: {
                title: '20%',
                subtitle: 'фонда идет на обеспечение стабильности и развитие проекта:',
                list: [
                    {
                        percent: '5%',
                        text: 'на вознаграждение команды'
                    },
                    {
                        percent: '5%',
                        text: 'на маркетинг и продвижение'
                    },
                    {
                        percent: '5%',
                        text: 'на реферальную программу'
                    },
                    {
                        percent: '3%',
                        text: 'на выплату инвесторам'
                    },
                    {
                        percent: '1%',
                        text: 'на благотворительные инициативы'
                    },
                    {
                        percent: '1%',
                        text: 'в фонд Ton Foundation для поддержки экосистемы'
                    }
                ]
            },
            winner: {
                title: '80%',
                subtitle: 'призового фонда распределяются среди победителей. Количество победителей и минимальная сумма приза напрямую зависят от числа купленных билетов. В таблице ниже указаны минимальные суммы выплат в зависимости от числа проданных билетов:',
                table: {
                    header: [
                        'Количество билетов',
                        'Минимальный приз (TON)'
                    ],
                    body: [
                        {
                            tickets: 20000,
                            prize: 1
                        },
                        {
                            tickets: 200000,
                            prize: 2
                        },
                        {
                            tickets: 500000,
                            prize: 2.5
                        },
                        {
                            tickets: 1000000,
                            prize: 5
                        },
                        {
                            tickets: 2000000,
                            prize: 10
                        },
                        {
                            tickets: 4000000,
                            prize: 20
                        },
                        {
                            tickets: 10000000,
                            prize: 50
                        },
                        {
                            tickets: 20000000,
                            prize: 100
                        }
                    ]
                }
            }
        },
        win: {
            title: 'Принципы распределения выигрышей',
            subtitle: 'Основная часть выигрыша уходит на первые несколько мест, чтобы максимально вознаградить наиболее удачливых участников:',
            first: {
                title: '🥇 1-е место',
                subtitle: 'получает 40% от общего призового фонда.'
            },
            second: {
                title: '🥈 2-е место',
                subtitle: 'получает 10% от общего призового фонда.'
            },
            text: 'Далее выплаты распределяются по принципу золотого сечения:',
            third: {
                title: '🥉 3-е место',
                subtitle: 'получает 6.18%'
            },
            fourth: {
                title: '🎉 4-е место',
                subtitle: 'получает 3.82%'
            },
            list: [
                'и так далее, пока сумма выплаты не станет меньше минимальной допустимой суммы (установленной по таблице).',
                'Когда выплаты для следующих участников становятся меньше минимального порога, все оставшиеся победители получают фиксированную сумму до полного распределения банка.',
                'Если после всех выплат остается небольшая сумма, она добавляется к призу за 1-е место.'
            ]
        },
        example: {
            title: 'Пример распределения',
            subtitle: 'Допустим, было продано 200 билетов, что формирует призовой фонд в 20 TON. Согласно таблице, минимальная выплата составляет 1 TON.',
            text: 'Выплаты распределяются так:',
            first: {
                title: '🥇 1-е место',
                subtitle: '8 TON (40%)'
            },
            second: {
                title: '🥈 2-е место',
                subtitle: '2 TON (10%)'
            },
            third: {
                title: '🥉 3-е место',
                subtitle: '1.24 TON (6.18%)'
            },
            fourth: {
                title: '🎉 4-е место',
                subtitle: '(вместо расчетных 0.76 TON, так как минимальная сумма — 1 TON)'
            },
            list: [
                'После первых трех мест осталось распределить 4.76 TON. Эти средства делятся между следующими победителями (4-е, 5-е и 6-е место) по 1.24 TON каждому. Наконец, остаток в 1.04 TON прибавляется к призу за 1-е место, увеличивая его до 9.04 TON.',
                'Таким образом, ToloTon предлагает честное, прозрачное и гармоничное распределение призов, где каждый участник имеет шанс выиграть реальные TON, а процесс выбора победителей защищен передовыми криптографическими технологиями.'
            ]
        }
    },
    tabbar: {
        main: 'Home',
        tasks: 'Earn',
        game: 'Games',
        referrals: 'Friends',
        profile: 'Profile'
    },
  },
  ru: {
    welcome: {
        headline: 'Привет!',
        title: 'Добро пожаловать в Toloton!',
        subtitle: 'Toloton - первая полностью прозрачная лотерея на блокчейне TON',
        list: [
            'Каждый участник вносит $1',
            'Победитель определяется случайным образом раз в день и забирает 80 % банка'
        ],
        text: 'Наш смарт-контракт прошел аудит со стороны TON Foundation. Мы определяем победителя с помощью механизма ECVRF, который гарантирует случайный выбор победителя.',
        button: {
            main: 'Начать',
            secondary: 'Как распределяется банк?'
        }
    },
    main: {
        roundTime: 'До конца раунда',
        bank: {
            text: 'Собрано в банке',
            participants: 'игроков'
        },
        charity: {
            title: 'Благотворительность',
            text: 'Мы помогаем'
        },
        distribution: {
            title: 'Распределение',
            text: 'Всё прозрачно'
        },
        rules: {
            title: 'Правила',
            text: 'правила игры'
        },
        history: {
            title: 'История',
            text: 'история игр'
        },
        button: {
            wallet: 'Подключить кошелёк',
            rules: 'Правила игры',
            history: 'История игр',
            main: 'Участвовать за TON или TLT'
        },
        connectWallet: {
            title: 'Подключить кошелек',
            text: 'Подключите свой кошелек, чтобы мы знали, куда отправить ваш выигрыш.',
            button: {
                next: 'Продолжить',
                skip: 'Отмена'
            }
        }
    },
    history: {
        title: 'История игр',
        subtitle: 'История',
        totalPaid: 'Всего выплачено',
        donatedToCharity: 'Отправлено на благотворительность',
        tabs: {
            all: 'Все игры',
            participated: 'Игры с моим участием'
        },
        event: {
            win: 'Вы выиграли',
            bank: 'Банк',
            ticket: 'Ваши билеты'
        }
    },
    winners: {
        title: 'Победители',
        button: 'Посмотреть'
    },
    eventInfo: {
        title: 'Лотерея',
        prizeFund: 'Призовой фонд',
        win: 'Вы выиграли',
        lost: 'Вы не выиграли',
        referrals: 'Друзья',
        referralsFriends: 'Друзья друзей',
        date: 'Дата розыгрыша',
        winners: 'Победители',
        winnersButton: 'победителей',
        charity: 'Отправлено на благотворительность'
    },
    game: {
        title: 'Игры',
        button: 'Статистика игр',
        money: {
            title: 'Монетка',
            text: 'Классическая игра «Орёл или решка»'
        },
        box: {
            title: 'Сундук',
            text: 'Угадай, в какой коробке монетка'
        },
        subtitle: 'TLT лотереи',
        event: 'лотерея',
        players: ['игрок', 'игрока', 'игроков'],
        from: 'из',
        state: {
            participant: 'Вы участвуете',
            win: 'Вы выиграли'
        },
        rate: 'Ставка',
        bank: 'Банк',
        round: 'До конца раунда',
        tickets: 'Билеты',
        prize: 'Приз',
        buttonEvent: 'Участвовать за',

        successMiniEvent: {
            title: 'Ставка сделана!',
            text: 'Вы участвуете! Ждите результатов - удачи!'
        }
    },
    gameBox: {
        title: 'Сундук',
        subtitle: 'Угадай, в какой коробке монетка',
        result: 'Ваш выигрыш',
        box: 'Коробка'
    },
    gameCoin: {
        title: 'Монетка',
        subtitle: 'Классическая игра «Орёл или решка»',
        result: 'Ваш выигрыш',
        obverse: 'Орёл',
        reverse: 'Решка'
    },
    profile: {
        title: 'Профиль',
        invitation: {
            text: 'пригласил',
            plural: ['друга', 'друзей', 'друзей']
        },
        wallet: 'Кошелёк',
        theme: 'Тёмная тема',
        language: 'Язык',
        button: {
            main: 'Купить TLT за Stars',
            logout: 'Выйти'
        }
    },
    referrals: {
        title: 'Друзья',
        subtitle: 'Привлекайте своих друзей и побеждайте вместе!',
        rewardsForFriends: {
            title: 'Награды за друзей',
            list: [
                {
                    percent: '2.6%',
                    text: 'От ставки друга'
                },
                {
                    percent: '1.6%',
                    text: 'От выигрыша друга'
                }
            ]
        },
        rewardsForFriendsOfFriends: {
            title: 'Награды за друзей друзей',
            list: [
                {
                    percent: '0.8%',
                    text: 'От ставки друга друга'
                },
                {
                    percent: '0.4%',
                    text: 'От выигрыша друга друга'
                }
            ]
        },
        button: {
            inviteFriends: 'Пригласить друзей',
            copyLink: 'Копировать ссылку-приглашение',
            copiedLink: 'Ссылка скопирована',
            statistic: 'Статистика начислений',
            copyLinkShort: 'Ссылка',
            copiedLinkShort: 'Скопировано',
        },
        plural: ['друг', 'друга', 'друзей'],
        totalPaid: 'Всего выплачено',
        payments: 'выплаты'
    },
    statisticsReferrals: {
        title: 'Статистика начислений',
        totalPaid: 'Всего выплачено',
        totalReferrals: 'Всего друзей',
    },
    tasks: {
        title: 'Задания',
        subtitle: 'Выполняй задачи и получай TLT',
        button: {
            main: 'Забрать ежедневный TLT',
            start: 'Сделать'
        },
        text: 'У вас есть возможность получать случайное количество TLT раз в день. Полученные TLT сгорают в течение 24 часов.',
        list: [
            {
                icon: '🎲',
                title: 'Смотри ролики',
                status: '0/20',
                tlt: '1 000'
            },
            {
                icon: '💬',
                title: 'Подпишись на Telegram канал',
                status: null,
                tlt: '1 000'
            }
        ]
    },
    language: {
        title: 'Выберите язык',
        en: 'Английский',
        ru: 'Русский'
    },
    statisticsGame: {
        title: 'Статистика'
    },

    modalMainEvent: {
        title: 'Количество билетов',
        tab: {
            ton: 'Купить за TON',
            tlt: 'Купить за TLT'
        },
        ticket: 'билет',
        text: 'Больше билетов – больше шанс выиграть!',
        button: 'Купить за'
    },
    modalWin: {
        title: 'Поздравляем, вы выиграли!',
        subtitle: 'Приз уже отправлен на ваш кошелёк',
        text: 'Спасибо, что приняли участие в нашей лотерее и сделали доброе дело вместе с нами. Поздравляем и желаем удачи в следующих розыгрышах.',
        charity: '1% от вашего выигрыша передается на благотворительность',
        button: {
            transaction: 'Смотреть транзакцию',
            close: 'Понятно'
        }
    },
    modalLost: {
        title: 'Спасибо за участие!',
        subtitle: 'Спасибо за участие в розыгрыше',
        text: 'Хотя вам не посчастливилось стать победителем этого розыгрыша, спасибо за участие в лотерее.',
        button: 'Понятно'
    },
    modalCharity: {
        title: 'Благотворительность',
        text: [
            'Мы верим, что каждый розыгрыш может принести не только радость победителям, но и пользу тем, кто нуждается в поддержке.',
            '1% от каждого розыгрыша идет в фонд поддержки благотворительных проектов. Участвуя в акции, вы помогаете нуждающимся'
        ],
        button: 'Понятно'
    },
    modalDistribution: {
        title: '80% вам, а 20% нам',
        list: [
            {
                title: 'Поддержка и развитие команды проекта',
                percent: '5%'
            },
            {
                title: 'Инвесторам, которые поддержали проект на ранних стадиях',
                percent: '5%'
            },
            {
                title: 'Для продвижения и рекламы',
                percent: '4%'
            },
            {
                title: 'Для реферальной программы, если есть приглашенные участники',
                percent: '3,75%'
            },
            {
                title: 'Для благотворительности',
                percent: '1%'
            },
            {
                title: 'Для Ton Foundation, чтобы поддержать Развитие экосистемы TON',
                percent: '1%'
            }
        ],
        button: 'Понятно'
    },
    modalReferrals: {
        title: 'Благодарим вас за активное участие и привлечение друзей',
        text: 'Оплата за приглашения',
        button: 'Понятно'
    },
    modalTasks: {
        title: 'Поздравляем!',
        button: 'Забрать'
    },
    loading: {
        text: 'Загрузка...'
    },
    rules: {
        title: 'Game rules',
        howWork: {
            title: 'Как работает ToloTon?',
            list: [
                'Каждый день в 12:00 UTC в ToloTon проходит главное событие — Main Event, где участники могут выиграть TON. Победители выбираются с использованием смарт-контракта и передовой технологии случайного выбора — Elliptic Curve Verifiable Random Function (ECVRF). Этот алгоритм полностью прозрачен и верифицируем, что гарантирует честность каждого розыгрыша.',
                'Для участия в главном розыгрыше достаточно приобрести хотя бы один билет. Стоимость билета — 0.1 TON или 1000 TLT — это наша внутренняя валюта, которая напрямую привязана к TON (1 TON = 10 000 TLT).',
                'Таким образом, участники могут сразу купить билет за 0.1 TON и принять участие в розыгрыше. А также получают уникальную возможность заработать TLT через выполнение заданий, игры и другие активности в приложении, и использовать их для участия в розыгрыше. ',
                'Все призы выплачиваются в TON, что даёт возможность выигрывать TON каждый день без каких-либо вложений.'
            ]
        },
        selectWinner: {
            title: 'Честность и прозрачность выбора победителей',
            text: 'Процесс выбора победителей строится на работе смарт-контракта, который использует криптографическую функцию ECVRF для создания случайного результата. Это обеспечивает полную независимость и объективность розыгрыша, гарантируя, что ни один из участников не может повлиять на исход. Любой желающий может проверить результаты лотереи, обратившись к открытому смарт-контракту.',
            text2: [
                {
                    type: 'text',
                    text:'Мы основали наш механизм на научных стандартах, подтвержденных следующими исследованиями: '
                },
                {
                    type: 'link',
                    text: 'RFC 9381',
                    link: 'https://datatracker.ietf.org/doc/html/rfc9381'
                },
                {
                    type: 'text',
                    text: ' и '
                },
                {
                    type: 'link',
                    text: 'RFC 6979',
                    link: 'https://datatracker.ietf.org/doc/html/rfc6979'
                },
                {
                    type: 'text',
                    text: '. Эти источники позволяют глубже понять принципы работы ECVRF и его роль в обеспечении безопасности и справедливости.'
                }
            ]
        },
        prizeFund: {
            title: 'Распределение призового фонда',
            subtitle: 'Призовой фонд в ToloTon распределяется таким образом, чтобы максимально вовлечь участников и вознаградить их усилия.',
            projectDevelopment: {
                title: '20%',
                subtitle: 'фонда идет на обеспечение стабильности и развитие проекта:',
                list: [
                    {
                        percent: '5%',
                        text: 'на вознаграждение команды'
                    },
                    {
                        percent: '5%',
                        text: 'на маркетинг и продвижение'
                    },
                    {
                        percent: '5%',
                        text: 'на реферальную программу'
                    },
                    {
                        percent: '3%',
                        text: 'на выплату инвесторам'
                    },
                    {
                        percent: '1%',
                        text: 'на благотворительные инициативы'
                    },
                    {
                        percent: '1%',
                        text: 'в фонд Ton Foundation для поддержки экосистемы'
                    }
                ]
            },
            winner: {
                title: '80%',
                subtitle: 'призового фонда распределяются среди победителей. Количество победителей и минимальная сумма приза напрямую зависят от числа купленных билетов. В таблице ниже указаны минимальные суммы выплат в зависимости от числа проданных билетов:',
                table: {
                    header: [
                        'Количество билетов',
                        'Минимальный приз (TON)'
                    ],
                    body: [
                        {
                            tickets: 20000,
                            prize: 1
                        },
                        {
                            tickets: 200000,
                            prize: 2
                        },
                        {
                            tickets: 500000,
                            prize: 2.5
                        },
                        {
                            tickets: 1000000,
                            prize: 5
                        },
                        {
                            tickets: 2000000,
                            prize: 10
                        },
                        {
                            tickets: 4000000,
                            prize: 20
                        },
                        {
                            tickets: 10000000,
                            prize: 50
                        },
                        {
                            tickets: 20000000,
                            prize: 100
                        }
                    ]
                }
            }
        },
        win: {
            title: 'Принципы распределения выигрышей',
            subtitle: 'Основная часть выигрыша уходит на первые несколько мест, чтобы максимально вознаградить наиболее удачливых участников:',
            first: {
                title: '🥇 1-е место',
                subtitle: 'получает 40% от общего призового фонда.'
            },
            second: {
                title: '🥈 2-е место',
                subtitle: 'получает 10% от общего призового фонда.'
            },
            text: 'Далее выплаты распределяются по принципу золотого сечения:',
            third: {
                title: '🥉 3-е место',
                subtitle: 'получает 6.18%'
            },
            fourth: {
                title: '🎉 4-е место',
                subtitle: 'получает 3.82%'
            },
            list: [
                'и так далее, пока сумма выплаты не станет меньше минимальной допустимой суммы (установленной по таблице).',
                'Когда выплаты для следующих участников становятся меньше минимального порога, все оставшиеся победители получают фиксированную сумму до полного распределения банка.',
                'Если после всех выплат остается небольшая сумма, она добавляется к призу за 1-е место.'
            ]
        },
        example: {
            title: 'Пример распределения',
            subtitle: 'Допустим, было продано 200 билетов, что формирует призовой фонд в 20 TON. Согласно таблице, минимальная выплата составляет 1 TON.',
            text: 'Выплаты распределяются так:',
            first: {
                title: '🥇 1-е место',
                subtitle: '8 TON (40%)'
            },
            second: {
                title: '🥈 2-е место',
                subtitle: '2 TON (10%)'
            },
            third: {
                title: '🥉 3-е место',
                subtitle: '1.24 TON (6.18%)'
            },
            fourth: {
                title: '🎉 4-е место',
                subtitle: '(вместо расчетных 0.76 TON, так как минимальная сумма — 1 TON)'
            },
            list: [
                'После первых трех мест осталось распределить 4.76 TON. Эти средства делятся между следующими победителями (4-е, 5-е и 6-е место) по 1.24 TON каждому. Наконец, остаток в 1.04 TON прибавляется к призу за 1-е место, увеличивая его до 9.04 TON.',
                'Таким образом, ToloTon предлагает честное, прозрачное и гармоничное распределение призов, где каждый участник имеет шанс выиграть реальные TON, а процесс выбора победителей защищен передовыми криптографическими технологиями.'
            ]
        }
    },
    tabbar: {
        main: 'Главная',
        tasks: 'Задания',
        game: 'Игры',
        referrals: 'Друзья',
        profile: 'Профиль'
    },
  },
};

// Создаем экземпляр i18n
const i18n = createI18n({
  locale: 'en', // Устанавливаем язык по умолчанию
  fallbackLocale: 'en', // Запасной язык, если перевода нет
  messages, // Загружаем переводы
});

export default i18n;