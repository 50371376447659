<template>
    <div :class="$style.RoundTimer">
        <div class="flex justify-center items-center">
            <div v-if="design !== 'mini-game'" :class="$style.time" class="color-text-primary">
                <span>{{ prettyDate( diff.hours ) }}</span>
            </div>

            <div v-if="design !== 'mini-game'" :class="$style.separator">
                <div :class="$style.circle" class="color-bg-secondary" />
                <div :class="$style.circle" class="color-bg-secondary" />
            </div>

            <div :class="[$style.time, { 'text-white': design === 'mini-game' }, {'color-text-primary': design !== 'mini-game'}]">
                <span>{{ prettyDate( diff.minutes ) }}</span>
            </div>

            <div :class="$style.separator">
                <div :class="[$style.circle, { 'bg-white opacity-50': design === 'mini-game' }, {'color-bg-secondary': design !== 'mini-game'}]" />
                <div :class="[$style.circle, { 'bg-white opacity-50': design === 'mini-game' }, {'color-bg-secondary': design !== 'mini-game'}]" />
            </div>

            <div :class="[$style.time, { 'text-white': design === 'mini-game' }, {'color-text-primary': design !== 'mini-game'}]">
                <span>{{ prettyDate( diff.seconds ) }}</span>
            </div>
        </div>

        <p class="text-center subheadline-bold-text" :class="[{'text-white': design === 'mini-game'}, {'color-text-primary': design !== 'mini-game'}]">
            {{ $t('main.roundTime') }}
        </p>
    </div>
</template>
  
<script>
import dayjs from "dayjs"
import duration from "dayjs/plugin/duration"
import { prettyDateValue } from '@/lib/utils'

dayjs.extend( duration )

const nullDiff = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
}

const UPDATE_INTERVAL = 1000

export default {
    name: 'MRoundTimer',
    props: {
        date: {
            type: Number,
            default: null
        },
        design: {
            type: String,
            default: "default",
            validator( val )
            {
                return [ "default", "mini-game" ].includes( val.toLowerCase() )
            }
        },
    },
    data()
    {
        return {
            time: {
                hours: 26,
                minutes: 53,
                seconds: 9
            },

            endTime: dayjs.unix( this.date ),
            diff: JSON.parse( JSON.stringify( nullDiff ) ),
            timerID: 0
        }
    },

    mounted()
    {
        this.updateDiff()
        this.timerID = setInterval( this.updateDiff, UPDATE_INTERVAL )
    },

    unmounted()
    {
        clearInterval( this.timerID )
        this.timerID = 0
    },

    methods:
    {
        updateDiff()
        {
            const now = dayjs()
            const result = this.endTime.diff( now )

            if ( result <= 0 )
            {
                Object.assign( this.diff, nullDiff )
                clearInterval( this.timerID )
                this.timerID = 0
                return
            }

            const durationObj = dayjs.duration( result )

            Object.assign( this.diff, {
                hours: durationObj.hours(),
                minutes: durationObj.minutes(),
                seconds: durationObj.seconds()
            })
        },

        prettyDate( value )
        {
            return prettyDateValue( value )
        }
    }
}
</script>

<style lang="scss" module>
.RoundTimer {
    @apply grid gap-8;

    .time {
        @apply grid gap-1 font-sfProRounded text-24 leading-24 tracking-0.4;
    }

    .separator {
        @apply grid gap-4 mx-4 h-max;
    }

    .circle {
        @apply w-3 h-3 rounded-3;
    }
}
</style>  