<template>
    <div :class="$style.BlurGradient">
        <div :class="$style.wrap">
            <div 
                :class="[$style.circle, $style.circle1]" 
                class="bg-blurCircle-1 dark:bg-darkBlurCircle-1" 
            />
            <div 
                :class="[$style.circle, $style.circle2]" 
                class="bg-blurCircle-2 dark:bg-darkBlurCircle-2" 
            />
            <div 
                :class="[$style.circle, $style.circle3]" 
                class="bg-blurCircle-3 dark:bg-darkBlurCircle-3" 
            />
            <div 
                :class="[$style.circle, $style.circle4]" 
                class="bg-blurCircle-4 dark:bg-darkBlurCircle-4" 
            />
        </div>
    </div>
</template>
  
<script>
export default {
    name: 'MBlurGradient'
}
</script>

<style lang="scss" module>
.BlurGradient {
    .wrap {
        @apply relative w-320 h-320 blur-[60px];
    }

    .circle {
        @apply w-200 h-200 rounded-full opacity-40 transition-colors duration-500;
    }

    .circle1 {
        @apply absolute top-[100px] left-0 ;
        animation: move1 3s ease-out infinite;
    }

    .circle2 {
        @apply absolute top-0 left-22;
        animation: move2 3s ease-out infinite;
    }

    .circle3 {
        @apply absolute top-32 left-[100%];
        animation: move3 3s ease-out infinite;
    }

    .circle4 {
        @apply absolute top-[100%] left-[93px] translate-y-[-100%];
        animation: move4 3s ease-out infinite;
    }
}

@keyframes move1 {
  0% {
    transform: translateX(0) translateY(0);
    opacity: 0.5;
  }
  50% {
    transform: translateX(147px) translateY(-117px);
    opacity: 1;
    @apply bg-blurCircle-1;
  }
  100% {
    transform: translateX(0) translateY(0);
    opacity: 0.5;
  }
}

@keyframes move2 {
  0% {
    transform: translateX(0) translateY(0);
    opacity: 0.5;
  }
  50% {
    transform: translateX(138px) translateY(144px);
    opacity: 1;
    @apply bg-blurCircle-2;
  }
  100% {
    transform: translateX(0) translateY(0);
    opacity: 0.5;
  }
}

@keyframes move3 {
  0% {
    transform: translateX(-100%) translateY(0);
    opacity: 0.5;
  }
  50% {
    left: 22px;
    transform: translateX(0) translateY(68px);
    opacity: 1;
    @apply bg-blurCircle-3;
  }
  100% {
    transform: translateX(-100%) translateY(0);
    opacity: 0.5;
  }
}

@keyframes move4 {
  0% {
    transform: translateX(0) translateY(-100%);
    opacity: 0.5;
  }
  50% {
    top: 34px;
    transform: translateX(-80px) translateY(0);
    opacity: 1;
    @apply bg-blurCircle-4;
  }
  100% {
    transform: translateX(0) translateY(-100%);
    opacity: 0.5;
  }
}
</style>  