<template>
    <div v-if="!isLoading" :class="$style.Welcome">
        <MBlurGradient :class="$style.gradientTop" />
        <MBlurGradient :class="$style.gradientBottom" />
        <img src="@/assets/images/welcome.png" class="ml-auto w-[75%] h-auto" />

        <C :class="[$style.wrap, $style.content]" class="mt-auto pb-20">
            <div :class="$style.block">
                <p class="headline-text">
                    👋 <span class="color-text-secondary">{{ $t('welcome.headline') }}</span>
                </p>
                <h1 class="title1-text color-text-primary">
                    {{ $t('welcome.title') }}
                </h1>
                <p class="body-text color-text-secondary">
                    {{ $t('welcome.subtitle') }}
                </p>
            </div>

            <ul :class="$style.wrap">
                <li 
                    v-for="(item, index) in welcomeList" 
                    :key="index" 
                    class="flex subheadline-text color-text-primary"
                >
                    <span class="mr-8 block subheadline-bold-text color-text-main">–</span>{{ item }}
                </li>
            </ul>

            <p class="subheadline-text color-text-secondary">
                {{ $t('welcome.text') }}
            </p>

            <div :class="$style.block" class="mt-24 pb-16">
                <AButton type="internal" link="/main-event" :text="$t('welcome.button.main')" right-arrow class="w-full" />
                <!-- <AButton :text="$t('welcome.button.secondary')" design="secondary" class="w-full" @click="showModalDistribution = true" /> -->
            </div>
        </C>

        <Modal v-if="showModalDistribution" :show="showModalDistribution" @close="showModalDistribution = false">
            <ModalDistribution @close="showModalDistribution = false" />
        </Modal>
    </div>
    <div v-else :class="$style.Welcome">
        <Loader />
    </div>
</template>
  
<script>
import C from '../AppContainer.vue'
import AButton from '../A/Button.vue'
import MBlurGradient from '../M/BlurGradient.vue'
import Modal from '../M/Modal.vue'
import ModalDistribution from '../Modal/Distribution.vue'
import Loader from './Loader.vue'

export default {
    name: 'OWelcome',
    data()
    {
        return {
            showModalDistribution: false,
            isLoading: true
        }
    },
    components: 
    {
        C,
        AButton,
        MBlurGradient,
        Modal,
        ModalDistribution,
        Loader
    },
    computed: 
    {
        welcomeList() 
        {
            return this.$i18n.messages[this.$i18n.locale].welcome.list;
        },
    },

    mounted()
    {
        const initData = 'auth_date=1683036782&query_id=AAEBBFC1A2cZD_4gH&user=%7B%22id%22%3A123456789%2C%22first_name%22%3A%22John%22%2C%22last_name%22%3A%22Doe%22%2C%22username%22%3A%22johndoe%22%2C%22language_code%22%3A%22en%22%7D&hash=3c78b9f9281f3a3ab456f8f917e96aa9d'
        // const initData = window.Telegram.WebApp.initData

        const params = new URLSearchParams(initData)

        // const authDate = params.get('auth_date')
        // const queryId = params.get('query_id')
        // const hash = params.get('hash')

        const user = JSON.parse(decodeURIComponent(params.get('user')))

        if ( user && user.id )
        {
            this.isLoading = false
        }
    }
}
</script>

<style lang="scss" module>
.Welcome {
    @apply relative py-16 flex flex-col h-full overflow-x-hidden overflow-y-scroll;
    z-index: 1;

    .gradientTop {
        @apply fixed -top-80 left-[100%] translate-x-[-270px];
        z-index: -1;
    }

    .gradientBottom {
        @apply fixed top-[100%] left-[-77px] translate-y-[-300px];
        z-index: -1;
    }

    .content {
        @apply relative;
        z-index: 1;
    }

    .wrap {
        @apply grid gap-24;
    }

    .block {
        @apply grid gap-12;
    }
}
</style>  