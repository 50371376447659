<template>
    <label :class="$style.switch">
      <input type="checkbox" v-model="isChecked" @change="$emit('update', isChecked)">
      <span :class="[$style.slider, { [$style.sliderDark]:isDarkTheme }]"></span>
    </label>
</template>
  
<script>
import { mapGetters } from 'vuex'
  export default {
    name: 'ASwitcher',
    props: {
        value: {
            type: Boolean,
            default: false
        }
    },
    data() {
      return {
        isChecked: this.value,
      };
    },
    computed:
    {
      ...mapGetters(['isDarkTheme']),
    }
  };
</script>
  
<style lang="scss" module>
  /* Контейнер для switch */
  .switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 24px;
  }
  
  /* Скрываем стандартный checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* Слайдер (переключатель) */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #F2F2F7;
    transition: 0.4s;
    border-radius: 35px; /* Закругляем весь блок */
  }

  .sliderDark {
    background-color: #0E0E15;
  }
  
  /* Стилизуем круг (ползунок внутри переключателя) */
  .slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 2px;
    top: 3px;
    background-color: rgba(60, 60, 67, 0.6);
    transition: 0.4s;
    border-radius: 50%; /* Делаем ползунок кругом */
  }

  .sliderDark:before {
    background-color: rgba(185, 185, 204, 0.6);
  }
  
  /* Когда переключатель включен (checked) */
  input:checked + .slider {
    background-color: #007AFF; /* Синий цвет для активного состояния */
  }

  input:checked + .sliderDark {
    background-color: #298FFF; /* Синий цвет для активного состояния */
  }
  
  /* Сдвигаем ползунок вправо, когда переключатель включен */
  input:checked + .slider:before {
    transform: translateX(18px);
    background-color: white; /* Цвет ползунка в активном состоянии */
  }
</style>  