<template>
    <div :class="$style.InputRange">
        <input
            :value="value"
            :min="min"
            :max="max"
            type="range"
            @input="$emit('input', $event)"
            :class="[$style.slider, { [$style.darkSlider]:isDarkTheme }]"
            :style="sliderStyle"
        />
    </div>
</template>
  
<script>
import { mapGetters } from 'vuex'

export default {
    name: 'AInputRange',
    props: {
        value:
		{
			type: Number,
			default: 0
		},
        min:
		{
			type: Number,
			default: 0
		},
        max:
		{
			type: Number,
			default: 0
		},
    },
    computed:
    {
        ...mapGetters(['isDarkTheme']),

        colorLeft()
        {
            return this.isDarkTheme ? '#298FFF' : '#007AFF'
        },

        colorRight()
        {
            return this.isDarkTheme ? 'rgba(0, 122, 255, 0.1)' : 'rgba(41, 143, 255, 0.1)'
        },

        sliderStyle()
        {
            const percentage = ((this.value - this.min) / (this.max - this.min)) * 100
            return `background: linear-gradient(to right, ${this.colorLeft} ${percentage}%, ${this.colorRight} ${percentage}%);`
        }
    }
}
</script>

<style module>
.InputRange {
    /* width: 100%;
    max-width: 400px;
    margin: 0 auto;
    text-align: center;
    padding: 20px; */
    @apply p-0 border-none w-full;
}

/* Основной слайдер */
.slider {
    -webkit-appearance: none; /* Убирает дефолтные стили для webkit-браузеров */
    appearance: none;
    width: 100%;
    height: 12px; /* Высота трека */
    background: rgba(0, 122, 255, 0.1); /* Цвет правой части трека */
    border-radius: 63px;
    outline: none;
    transition: background 0.3s;
}

.darkSlider {
    background: rgba(41, 143, 255, 0.1);
}

.slider:hover {
  opacity: 1;
}

/* Стилизуем бегунок (ползунок) для Webkit-браузеров */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 36px; /* Размер бегунка */
  height: 36px; /* Размер бегунка */
  background: #007AFF; /* Синий цвет */
  border: 4px solid #ffffff; /* Белая окантовка */
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  position: relative;
}

.darkSlider::-webkit-slider-thumb {
    background: #298FFF; /* Синий цвет */
    border: 4px solid #1C1C25; /* Белая окантовка */
}

/* .slider::-webkit-slider-thumb:hover {
  background: #0056b3;
} */

/* Стилизуем бегунок для браузеров Firefox */
.slider::-moz-range-thumb {
  width: 36px;
  height: 36px;
  background: #007AFF;
  border: 4px solid #ffffff;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
}

.darkSlider::-moz-range-thumb {
    background: #298FFF;
    border: 4px solid #1C1C25;
}

/* .slider::-moz-range-thumb:hover {
  background: #0056b3;
} */

/* Стилизуем бегунок для IE */
.slider::-ms-thumb {
  width: 36px;
  height: 36px;
  background: #007AFF;
  border: 4px solid #ffffff;
  border-radius: 50%;
  cursor: pointer;
}

.darkSlider::-ms-thumb {
    background: #298FFF;
    border: 4px solid #1C1C25;
}

/* Прогресс бар для Firefox */
.slider::-moz-range-track {
  background: rgba(0, 122, 255, 0.1);
  height: 12px;
  border-radius: 63px;
}

.darkSlider::-moz-range-track {
    background: rgba(41, 143, 255, 0.1);
}

/* Прогресс бар для IE */
.slider::-ms-track {
  width: 100%;
  height: 12px;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

.slider::-ms-fill-lower {
    background: #007AFF;
    border-radius: 63px;
}

.darkSlider::-ms-fill-lower {
    background: #298FFF;
}

.slider::-ms-fill-upper {
    background: rgba(0, 122, 255, 0.1); 
    border-radius: 63px;
}

.darkSlider::-ms-fill-upper {
    background: rgba(41, 143, 255, 0.1);
}
</style>  
  