<template>
    <div :class="$style.container">
        <slot/>
    </div>
</template>
  
<script>
  export default {
    name: 'AppContainer'
  }
</script>
  
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style module>
.container {
    @apply w-full px-16;
}
</style>  