<template>
    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.69238 12.1235C4.35384 12.1235 4.06608 11.9775 3.8291 11.6855L0.541016 7.63574C0.447917 7.52572 0.380208 7.41781 0.337891 7.31201C0.299805 7.20622 0.280762 7.09619 0.280762 6.98193C0.280762 6.72803 0.365397 6.51855 0.534668 6.35352C0.703939 6.18848 0.917643 6.10596 1.17578 6.10596C1.46777 6.10596 1.71322 6.23079 1.91211 6.48047L4.66699 9.97168L10.0435 1.45312C10.1535 1.28385 10.2677 1.16536 10.3862 1.09766C10.5047 1.02572 10.6528 0.989746 10.8306 0.989746C11.0887 0.989746 11.3003 1.07015 11.4653 1.23096C11.6304 1.39176 11.7129 1.59701 11.7129 1.84668C11.7129 1.94824 11.696 2.0498 11.6621 2.15137C11.6283 2.25293 11.5754 2.35872 11.5034 2.46875L5.56201 11.6602C5.35889 11.9691 5.06901 12.1235 4.69238 12.1235Z" fill="currentColor"/>
    </svg>
</template>

<script>
export default {
    name: 'IconCheck'
}
</script>