<template>
    <div class="swiper-container">
        <swiper
            :slides-per-view="1.78" 
            :space-between="0" 
            :centered-slides="true"
            loop 
            @slideChange="onSlideChange"
        >
            <div 
                :class="[{'opacity-100': isPlayVideo}, {'opacity-0': !isPlayVideo}]"
                class="absolute left-[50%] bottom-0 translate-x-[-50%] py-16 transition-opacity duration-300"
            >
                <div class="relative w-200 h-200">
                    <video 
                        ref="videoPlayer" 
                        width="100%"
                        height="auto"
                        preload="auto"
                        playsinline
                        @ended="onVideoEnd"
                    >
                        <source src="video.mov" type="video/quicktime" />
                        <source src="video.webm" type="video/webm" />
                        Ваш браузер не поддерживает тег видео.
                    </video>

                    <p 
                        class="absolute bottom-15 left-[50%] translate-x-[-50%] text-white subheadline-bold-text w-max transition-opacity duration-300"
                        :class="{'opacity-0': !showResult}"
                    >
                        {{ $t('gameBox.result') }} 5 TLT
                    </p>
                </div>
            </div>
            <div 
                :class="[{'opacity-100': isPlayVideoEmpty}, {'opacity-0': !isPlayVideoEmpty}]"
                class="absolute left-[50%] bottom-0 translate-x-[-50%] py-16 transition-opacity duration-300"
            >
                <div class="relative w-200 h-200">
                    <video 
                        ref="videoPlayerEmpty" 
                        width="100%"
                        height="auto"
                        preload="auto"
                        playsinline
                        @ended="onVideoEnd"
                    >
                        <source src="box.mov" type="video/quicktime" />
                        <source src="box.webm" type="video/webm" />
                        Ваш браузер не поддерживает тег видео.
                    </video>

                    <p 
                        class="absolute bottom-15 left-[50%] translate-x-[-50%] text-white subheadline-bold-text w-max transition-opacity duration-300"
                        :class="{'opacity-0': !showResult}"
                    >
                        {{ $t('modalLost.title') }}
                    </p>
                </div>
            </div>

            <swiper-slide v-for="(slide, index) in slides" :key="index">
                <!-- && !isPlayVideo && !isPlayVideoEmpty -->
                <div 
                    class="py-16 transition-opacity duration-500" 
                    :class="[
                        {'opacity-40': activeIndex !== index },
                        {'transition-transform translate-x-[100%]': 
                            (isPlayVideo || isPlayVideoEmpty) && 
                            (( activeIndex !== slides.length - 1 && index === activeIndex + 1 ) || ( activeIndex === slides.length - 1 && index === 0 ) )
                        },
                        {'transition-transform translate-x-[-100%]': 
                            (isPlayVideo || isPlayVideoEmpty) && 
                            (( activeIndex !== 0 && index === activeIndex - 1 ) || ( activeIndex === 0 && index === slides.length - 1 ) )
                        },
                        {'delay-500 opacity-0': activeIndex === index && ( isPlayVideo || isPlayVideoEmpty ) }
                    ]"
                >
                    <AWallet :design="activeIndex === index ? 'main' : 'games'" class="mx-auto">
                        {{ $t('gameBox.box') }} #{{ slide.title }}
                    </AWallet>

                    <div class="relative w-200 h-200 mx-auto">
                        <img src="@/assets/images/box-empty.png" class="mx-auto w-200 h-200" />
                        <!-- <video 
                            ref="videoPlayer" 
                            width="100%"
                            height="auto"
                            preload="auto"
                            playsinline
                            @ended="onVideoEnd"
                        >
                            <source src="video.mov" type="video/quicktime" />
                            <source src="video.webm" type="video/webm" />
                            Ваш браузер не поддерживает тег видео.
                        </video> -->

                        <p 
                            class="absolute bottom-15 left-[50%] translate-x-[-50%] text-white subheadline-bold-text w-max transition-opacity duration-300"
                            :class="{'opacity-0': !showResult || activeIndex !== index}"
                        >
                            {{ $t('gameBox.result') }} 5 TLT
                        </p>
                    </div>
                </div>
            </swiper-slide>

            <div v-if="!allowTouchMode" class="absolute top-0 bottom-0 left-0 right-0 z-10" />
        </swiper>
    </div>
</template>
  
<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import AWallet from '../A/Wallet.vue'

export default {
    name: 'MSliderGameBox',
    components: {
        Swiper,
        SwiperSlide,
        AWallet
    },
    data() {
        return {
            slides: [
                { title: '1', content: 'Контент первого слайда' },
                { title: '2', content: 'Контент второго слайда' },
                { title: '3', content: 'Контент третьего слайда' },
                { title: '1', content: 'Контент первого слайда' },
                { title: '2', content: 'Контент второго слайда' },
                { title: '3', content: 'Контент третьего слайда' }
            ],
            activeIndex: 0,
            showResult: false,
            isPlayVideo: false,
            isPlayVideoEmpty: false,
            allowTouchMode: true
        }
    },
    mounted()
    {
        if (this.$refs.videoPlayer)
        {
            this.$refs.videoPlayer.addEventListener('loadedmetadata', () => {
                this.$refs.videoPlayer.play().then(() => {
                    this.$refs.videoPlayer.currentTime = 0.025
                    this.$refs.videoPlayer.pause()
                })
            })
        }
        
        if ( this.$refs.videoPlayerEmpty )
        {
            this.$refs.videoPlayerEmpty.addEventListener('loadedmetadata', () => {
                this.$refs.videoPlayerEmpty.play().then(() => {
                    this.$refs.videoPlayerEmpty.currentTime = 0.025
                    this.$refs.videoPlayerEmpty.pause()
                })
            })
        }
    },
    // mounted()
    // {
        // if ( this.$refs.videoPlayer )
        // {
            // this.$refs.videoPlayer[0].addEventListener('loadedmetadata', () => {
            //     this.$refs.videoPlayer[0].play().then(() => {
            //         this.$refs.videoPlayer[0].currentTime = 0.025
            //         this.$refs.videoPlayer[0].pause()
            //     })
            // })

            // this.$refs.videoPlayer[1].addEventListener('loadedmetadata', () => {
            //     this.$refs.videoPlayer[1].play().then(() => {
            //         this.$refs.videoPlayer[1].currentTime = 0.025
            //         this.$refs.videoPlayer[1].pause()
            //     })
            // })

            // this.$refs.videoPlayer[5].addEventListener('loadedmetadata', () => {
            //     this.$refs.videoPlayer[5].play().then(() => {
            //         this.$refs.videoPlayer[5].currentTime = 0.025
            //         this.$refs.videoPlayer[5].pause()
            //     })
            // })

            // this.$refs.videoPlayer[2].addEventListener('loadedmetadata', () => {
            //     this.$refs.videoPlayer[2].play().then(() => {
            //         this.$refs.videoPlayer[2].currentTime = 0.025
            //         this.$refs.videoPlayer[2].pause()
            //     })
            // })

            // this.$refs.videoPlayer[3].addEventListener('loadedmetadata', () => {
            //     this.$refs.videoPlayer[3].play().then(() => {
            //         this.$refs.videoPlayer[3].currentTime = 0.025
            //         this.$refs.videoPlayer[3].pause()
            //     })
            // })

            // this.$refs.videoPlayer[4].addEventListener('loadedmetadata', () => {
            //     this.$refs.videoPlayer[4].play().then(() => {
            //         this.$refs.videoPlayer[4].currentTime = 0.025
            //         this.$refs.videoPlayer[4].pause()
            //     })
            // })
        // }
    // },
    methods: {
        onSlideChange(swiper) {
            this.showResult = false
            this.activeIndex = swiper.realIndex
        },

        // onRenderVideo()
        // {
        //     this.$refs.videoPlayer.addEventListener('loadedmetadata', () => {
        //         this.$refs.videoPlayer.play().then(() => {
        //             this.$refs.videoPlayer.currentTime = 0.025
        //             this.$refs.videoPlayer.pause()
        //         })
        //     })

        //     this.$refs.videoPlayerEmpty.addEventListener('loadedmetadata', () => {
        //         this.$refs.videoPlayerEmpty.play().then(() => {
        //             this.$refs.videoPlayerEmpty.currentTime = 0.025
        //             this.$refs.videoPlayerEmpty.pause()
        //         })
        //     })
        // },

        notAllowTouchMode()
        {
            this.allowTouchMode = false
        },

        playVideo( result )
        {
            // if ( this.$refs.videoPlayer[ this.activeIndex ] )
            // {
            //     this.showResult = false
            //     this.isPlayVideo = true
            //     this.$refs.videoPlayer[ this.activeIndex ].play()
            // }

            // console.log('swiper', this.$refs.swiper)

            if ( result && this.$refs.videoPlayer )
            {
                this.showResult = false
                this.isPlayVideo = true
                

                setTimeout(() => {
                    this.$refs.videoPlayer.play()
                }, 1500);
            }
            else if ( !result && this.$refs.videoPlayerEmpty )
            {
                this.showResult = false
                this.isPlayVideoEmpty = true
                

                setTimeout(() => {
                    this.$refs.videoPlayerEmpty.play()
                }, 1500);
            }
        },

        onVideoEnd()
        {
            this.showResult = true
        }
    }
}
</script>

<style scoped>
.swiper-container {
    position: relative;
    width: 100%;
    height: auto;
}
</style>  