<template>
    <div :class="[ $style.Tab, $style[ design ], backgroundTab ]">
        <span 
            class="footnote-bold-text"
            :class="colorTab"
        >
            {{ title }}
        </span>
    </div>
</template>
  
<script>
export default {
    name: 'ATab',
    props: {
        title: {
            type: String,
            default: ''
        },
        design: {
            type: String,
            default: "default",
            validator( val )
            {
                return [ "default", "game" ].includes( val.toLowerCase() )
            }
        },
        isActive: {
            type: Boolean,
            default: false
        }
    },

    computed:
    {
        backgroundTab()
        {
            if ( this.design === 'game' )
            {
                return this.isActive ? 'bg-white' : 'color-bg-games'
            }
            else
            {
                return this.isActive ? 'color-bg-primary' : 'color-bg-background'
            }
        },

        colorTab()
        {
            if ( this.design === 'game' )
            {
                return this.isActive ? 'color-text-main' : 'text-white'
            }
            else
            {
                return this.isActive ? 'color-text-primary-inverse' : 'color-text-primary'
            }
        }
    }
}
</script>

<style module>
.Tab {
    @apply flex justify-center items-center px-12 py-8 rounded-10;

    &.game {
        @apply px-0;
    }
}
</style>  