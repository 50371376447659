<template>
    <div :class="$style.CircleBG">
        <div :class="$style.wrap">
            <div 
                :class="[
                    $style.block, 
                    $style.block1, 
                    { [$style.darkBlock]: isDarkTheme }
                ]" 
            />
            <div 
                :class="[
                    $style.block, 
                    $style.block2, 
                    { [$style.darkBlock]: isDarkTheme }
                ]" 
            />
            <div 
                :class="[
                    $style.block, 
                    $style.block3, 
                    { [$style.darkBlock]: isDarkTheme }
                ]" 
            />
        </div>
    </div>
</template>
  
<script>
import { mapGetters } from 'vuex'

export default {
    name: 'MCircleBG',
    computed: {
        ...mapGetters(['isDarkTheme']),
    },
}
</script>

<style lang="scss" module>
.CircleBG {
    .wrap {
        @apply relative w-[590px] h-[590px];
    }

    .block {
        @apply absolute top-[50%] left-[50%] rounded-full;
        background: rgba(255, 255, 255, 0.3);
        border: 1px solid rgba(255, 255, 255, 0.3);
    }

    .darkBlock {
        background: rgba(255, 255, 255, 0.01);
        border: 1px solid rgba(255, 255, 255, 0.02);
    }

    .block1 {
        @apply w-180 h-180;
        opacity: 0.9;
        transform: translate(-50%, -50%);
        animation: circleMove1 3s infinite;
    }

    .block2 {
        @apply w-[267px] h-[267px];
        opacity: 0.9;
        transform: translate(-50%, -50%);
        animation: circleMove2 3s infinite;
    }

    .block3 {
        @apply w-[388px] h-[388px];
        opacity: 0.9;
        transform: translate(-50%, -50%);
        animation: circleMove3 3s infinite;
    }
}

@keyframes circleMove1 {
    0%, 100% {
        opacity: 0.9;
        transform: translate(-50%, -50%) scale(1);
    }
    50% {
        opacity: 0;
        transform: translate(-50%, -50%) scale(1.3);
    }
}

@keyframes circleMove2 {
    0%, 100% {
        opacity: 0.9;
        transform: translate(-50%, -50%) scale(1);
    }
    50% {
        opacity: 0;
        transform: translate(-50%, -50%) scale(1.6);
    }
}

@keyframes circleMove3 {
    0%, 100% {
        opacity: 0.9;
        transform: translate(-50%, -50%) scale(1);
    }
    50% {
        opacity: 0;
        transform: translate(-50%, -50%) scale(1.52);
    }
}
</style>  