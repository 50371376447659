<template>
    <div :class="$style.Tasks">
        <span class="text-48">🤗</span>
        <h2 class="mt-20 text-center title1-text color-text-primary">
            {{ $t('modalTasks.title') }}
        </h2>

        <p class="mt-20 font-sfProRounded color-text-primary">
            <span class="text-42 leading-42">+{{ reward }}</span>
            <span class="text-24"> TLT</span>
        </p>

        <AButton :text="$t('modalTasks.button')" class="mt-20" @click="$emit('close')" />
    </div>
</template>
  
<script>
import AButton from '../A/Button.vue'

export default {
    name: 'ModalTasks',
    components: 
    {
        AButton
    },
    data()
    {
        return {
            reward: '1 000'
        }
    },
    computed: 
    {
        list() 
        {
            return this.$i18n.messages[this.$i18n.locale].modalCharity.text;
        },
    }
}
</script>

<style lang="scss" module>
.Tasks {
    @apply flex flex-col items-center;
}
</style>  