<template>
    <ATooltip :class="$style.TooltipConnectWallet">
        <template #content>
            <h3 class="mb-4 subheadline-bold-text color-text-primary">
                {{ $t('main.connectWallet.title') }}
            </h3>
            <p class="footnote-text color-text-primary">
                {{ $t('main.connectWallet.text') }}
            </p>
        </template>

        <template #buttons>
            <AButton 
                :text="$t('main.connectWallet.button.next')" 
                design="secondary" 
                size="sm" 
                class="mr-8"
                @click="$emit('apply')"
            />
            <AButton 
                :text="$t('main.connectWallet.button.skip')" 
                design="custom" 
                size="sm" 
                class="mr-8 color-text-main"
                @click="$emit('close')"
            />
        </template>
    </ATooltip>
</template>
  
<script>
import ATooltip from '../A/Tooltip.vue'
import AButton from '../A/Button.vue'

export default {
    name: 'TooltipConnectWallet',
    components: 
    {
        ATooltip,
        AButton
    },
    props: {
        title: {
            type: String,
            default: ''
        },
        text: {
            type: String,
            default: ''
        },
        isGreen: {
            type: Boolean,
            default: false
        },
    }
}
</script>

<style module>
.TooltipConnectWallet {
    @apply w-[300px];
}
</style>  