<template>
    <div :class="$style.Winners">
        <C class="pt-16 pb-4">
            <h1 class="large-title-text color-text-primary">
                {{ $t('winners.title') }}
            </h1>
        </C>

        <C :class="$style.wrap">
            <PropertyCard
                v-for="(item, i) in winners"
                :key="`winner-${i}`"
                :title="item.accountNO"
                :text="`${ item.ton } TON`"
                :button="$t('winners.button')"
                color-btn="blue"
                design="horizontal-link"
            />
        </C>
    </div>
</template>
  
<script>
import C from '../AppContainer.vue'
import PropertyCard from '../A/PropertyCard.vue'

export default {
    name: 'OWinners',
    components: 
    {
        C,
        PropertyCard
    },
    data()
    {
        return {
            winners: [
                {
                    accountNO: 'UQBP...LI8k',
                    ton: 165
                },
                {
                    accountNO: 'UQBP...LI8k',
                    ton: 165
                },
                {
                    accountNO: 'UQBP...LI8k',
                    ton: 165
                },
            ]
        }
    }
}
</script>

<style lang="scss" module>
.Winners {
    @apply relative h-full overflow-x-hidden overflow-y-scroll;

    .wrap {
        @apply py-16 grid gap-8;
    }
}
</style>  