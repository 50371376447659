<template>
    <div :class="$style.Charity">
        <span class="text-48">🍀</span>
        <h2 class="mt-20 title1-text color-text-primary">
            {{ $t('modalCharity.title') }}
        </h2>

        <p 
            v-for="(item, i) in list" 
            :key="`form-charity-text-${i}`"
            class="mt-20 text-center subheadline-text color-text-secondary"
        >
            {{ item }}
        </p>

        <AButton :text="$t('modalCharity.button')" class="mt-20" @click="$emit('close')" />
    </div>
</template>
  
<script>
import AButton from '../A/Button.vue'

export default {
    name: 'ModalCharity',
    components: 
    {
        AButton
    },
    computed: 
    {
        list() 
        {
            return this.$i18n.messages[this.$i18n.locale].modalCharity.text;
        },
    }
}
</script>

<style lang="scss" module>
.Charity {
    @apply flex flex-col items-center;
}
</style>  