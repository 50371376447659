<template>
    <Layout>
        <div :class="$style.Profile">
            <C>
                <h1 class="large-title-text color-text-primary">
                    {{ $t('profile.title') }}
                </h1>

                <UserCell :data="user" hidden-payment/>

                <div class="py-16">
                    <div class="p-16 pt-20 rounded-16 color-bg-widget">
                        <div class="flex justify-center">
                            <img src="@/assets/images/wallet.png" class="mr-10 w-[42px] h-[42px]" />
                            <p class="ml-10 font-sfProRounded color-text-primary">
                                <span class="text-42 leading-42">{{ wallet }}</span>
                                <span class="ml-8 text-24">TLT</span>
                            </p>
                        </div>

                        <AButton :text="$t('profile.button.main')" class="mt-16 w-full" />
                    </div>

                    <PropertyCard
                        :title="$t('profile.wallet')"
                        :text="numberWallet"
                        design="horizontal"
                        class="mt-8"
                    />

                    <PropertyCard
                        :title="$t('profile.theme')"
                        :value-switch="isDarkTheme"
                        design="toggle"
                        class="mt-8"
                        @updateSwitcher="onChangeTheme"
                    />

                    <PropertyCard
                        :title="$t('profile.language')"
                        :button="textLanguage"
                        type-button="internal"
                        link-button="/language"
                        design="horizontal-link"
                        class="mt-8"
                    />

                    <AButton :text="$t('profile.button.logout')" design="secondary" left-logout class="mt-8 w-full" />
                </div>
            </C>
        </div>
    </Layout>
</template>
  
<script>
import { mapGetters, mapMutations } from 'vuex'
import Layout from './Layout.vue'
import C from '../AppContainer.vue'
import AButton from '../A/Button.vue'
import PropertyCard from '../A/PropertyCard.vue'
import UserCell from '../A/UserCell.vue'
import { plural } from '@/lib/utils'

export default {
    name: 'OProfile',
    components: 
    {
        Layout,
        C,
        AButton,
        PropertyCard,
        UserCell
    },
    data()
    {
        return {
            user: {
                name: 'MatveyAnanev',
                friends: [
                    {
                        name: 'test'
                    },
                    {
                        name: 'test'
                    },
                    {
                        name: 'test'
                    },
                    {
                        name: 'test'
                    },
                    {
                        name: 'test'
                    },
                    {
                        name: 'test'
                    },
                    {
                        name: 'test'
                    },
                    {
                        name: 'test'
                    },
                    {
                        name: 'test'
                    },
                ]
            },
            wallet: '1 898',
            numberWallet: 'UQBP...LI8k'
        }
    },

    computed:
    {
        ...mapGetters(['isDarkTheme', 'language']),

        pluralText() 
        {
            return this.$i18n.messages[this.$i18n.locale].profile.invitation.plural;
        },

        pluralReferrals()
        {
            return plural( this.referrals, this.pluralText )
        },

        textLanguage()
        {
            return this.language === 'en' ? this.$t('language.en') : this.$t('language.ru')
        }
    },

    mounted()
    {
        if ( window.Telegram.WebApp && window.Telegram.WebApp.BackButton.isVisible )
        {
            window.Telegram.WebApp.BackButton.hide()
        }
    },

    methods:
    {
        ...mapMutations(['setTheme']),

        onChangeTheme(value)
        {
            const theme = value ? 'dark' : 'light'

            this.setTheme(theme)
            localStorage.setItem('theme', theme)
        }
    }
}
</script>

<style lang="scss" module>
.Profile {
    @apply relative py-16 h-full;
}
</style>  