<template>
    <div :class="$style.PromoCard" class="color-bg-widget">
        <div class="flex items-center">
            <div 
                class="flex justify-center items-center w-40 h-40 rounded-11"
                :class="[
                    {'bg-green-light': isGreen},
                    {'bg-blue-light': isBlue},
                    {'bg-red-light': !isGreen && !isBlue}
                ]"
            >
                <span class="text-26">{{ icon }}</span>
            </div>

            <div class="ml-8">
                <p class="callout-bold-text color-text-primary">
                    {{ title }}
                </p>
                <p class="footnote-bold-text color-text-secondary">
                    {{ text }}
                </p>
            </div>
        </div>

        <IconArrow class="ml-14 opacity-30 color-text-secondary" />
    </div>
</template>
  
<script>
import IconArrow from '../Icon/Arrow.vue'

export default {
    name: 'APromoCard',
    components: 
    {
        IconArrow
    },
    props: {
        icon: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        },
        text: {
            type: String,
            default: ''
        },
        isGreen: {
            type: Boolean,
            default: false
        },
        isBlue: {
            type: Boolean,
            default: false
        },
    }
}
</script>

<style module>
.PromoCard {
    @apply flex justify-between items-center px-16 py-18 rounded-16;
    /* min-w-278 w-278 */
}
</style>  