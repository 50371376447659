import { createRouter, createWebHistory } from 'vue-router'
import Welcome from '../components/O/Welcome.vue'
import Main from '../components/O/Main.vue'
import Game from '../components/O/Game.vue'
import Tasks from '../components/O/Tasks.vue'
import Referrals from '../components/O/Referrals.vue'
import Profile from '../components/O/Profile.vue'
import History from '../components/O/History.vue'
import EventInfo from '../components/O/EventInfo.vue'
import Winners from '../components/O/Winners.vue'
import StatisticsReferrals from '../components/O/StatisticsReferrals.vue'
import StatisticsGame from '../components/O/StatisticsGame.vue'
import Language from '../components/O/Language.vue'
import Rules from '../components/O/Rules.vue'

const routes = [
  // { path: '/', redirect: '/welcome' },
  { path: '/', name: 'Welcome', component: Welcome },
  { path: '/main-event', name: 'Main', component: Main },
  { path: '/game', name: 'Game', component: Game },
  { path: '/tasks', name: 'Tasks', component: Tasks },
  { path: '/referrals', name: 'Referrals', component: Referrals },
  { path: '/profile', name: 'Profile', component: Profile },
  { path: '/history', name: 'History', component: History },
  { path: '/event-info', name: 'EventInfo', component: EventInfo },
  { path: '/winners', name: 'Winners', component: Winners },
  { path: '/statistics-referrals', name: 'StatisticsReferrals', component: StatisticsReferrals },
  { path: '/statistics-game', name: 'StatisticsGame', component: StatisticsGame },
  { path: '/language', name: 'Language', component: Language },
  { path: '/rules', name: 'Rules', component: Rules }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router