<template>
    <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.67676 5.91699C6.67676 6.04395 6.65137 6.16455 6.60059 6.27881C6.55404 6.38883 6.47786 6.49463 6.37207 6.59619L1.49072 11.376C1.32568 11.5368 1.12467 11.6172 0.887695 11.6172C0.735352 11.6172 0.593587 11.5791 0.462402 11.5029C0.331217 11.4268 0.225423 11.3252 0.14502 11.1982C0.0688477 11.0713 0.0307617 10.9274 0.0307617 10.7666C0.0307617 10.5339 0.119629 10.3265 0.297363 10.1445L4.64551 5.91699L0.297363 1.68945C0.119629 1.51172 0.0307617 1.30436 0.0307617 1.06738C0.0307617 0.910807 0.0688477 0.769043 0.14502 0.64209C0.225423 0.510905 0.331217 0.407227 0.462402 0.331055C0.593587 0.254883 0.735352 0.216797 0.887695 0.216797C1.12467 0.216797 1.32568 0.297201 1.49072 0.458008L6.37207 5.23779C6.47363 5.33936 6.5498 5.44515 6.60059 5.55518C6.65137 5.6652 6.67676 5.78581 6.67676 5.91699Z" fill="currentColor"/>
    </svg>
</template>

<script>
export default {
    name: 'IconArrow'
}
</script>