<template>
    <div 
        :class="[$style.Tabbar, { 'z-0': overlayTabbar }, { 'z-10': !overlayTabbar }]" 
        class="bg-backgroundTabbar dark:bg-darkBackgroundTabbar"
    >
        <div :class="$style.content">
            <router-link to="/main-event" @click="onCLick" :class="[
                $style.block,
                {'color-text-main': isActiveMain},
                {'color-text-secondary': !isActiveMain}
            ]">
                <IconHome class="mb-4" />
                <span class="caption2-text">{{ $t('tabbar.main') }}</span>
            </router-link>
            <router-link to="/tasks" @click="onCLick" :class="[
                $style.block,
                {'color-text-main': isActiveTasks},
                {'color-text-secondary': !isActiveTasks}
            ]">
                <IconTasks class="mb-4" />
                <span class="caption2-text">{{ $t('tabbar.tasks') }}</span>
            </router-link>
            <router-link to="/game" @click="onCLick" :class="[
                $style.block,
                {'color-text-main': isActiveGame},
                {'color-text-secondary': !isActiveGame}
            ]">
                <IconGame class="mb-4" />
                <span class="caption2-text">{{ $t('tabbar.game') }}</span>
            </router-link>
            <router-link to="/referrals" @click="onCLick" :class="[
                $style.block,
                {'color-text-main': isActiveReferrals},
                {'color-text-secondary': !isActiveReferrals}
            ]">
                <IconReferrals class="mb-4" />
                <span class="caption2-text">{{ $t('tabbar.referrals') }}</span>
            </router-link>
            <router-link to="/profile" @click="onCLick" :class="[
                $style.block,
                {'color-text-main': isActiveProfile},
                {'color-text-secondary': !isActiveProfile}
            ]">
                <IconProfile class="mb-4" />
                <span class="caption2-text">{{ $t('tabbar.profile') }}</span>
            </router-link>
        </div>
    </div>
</template>
  
<script>
import IconHome from '../Icon/Home.vue'
import IconTasks from '../Icon/Tasks.vue'
import IconGame from '../Icon/Game.vue'
import IconReferrals from '../Icon/Referrals.vue'
import IconProfile from '../Icon/Profile.vue'

export default {
    name: 'OTabbar',
    components: 
    {
        IconHome,
        IconTasks,
        IconGame,
        IconReferrals,
        IconProfile
    },
    props: {
        overlayTabbar: {
            type: Boolean,
            default: false
        }
    },
    computed: 
    {
        isActiveMain() 
        {
            return this.$route.name === 'Main'
        },

        isActiveTasks() 
        {
            return this.$route.name === 'Tasks'
        },

        isActiveGame() 
        {
            return this.$route.name === 'Game'
        },

        isActiveReferrals() 
        {
            return this.$route.name === 'Referrals'
        },

        isActiveProfile() 
        {
            return this.$route.name === 'Profile'
        }
    },

    methods:
    {
        onCLick()
        {
            if ( window.Telegram.WebApp ) 
            {
                window.Telegram.WebApp.HapticFeedback.impactOccurred('light')
            }
        }
    }
}
</script>

<style lang="scss" module>
.Tabbar {
    @apply fixed bottom-0 left-0 right-0 pb-34;

    .content {
        @apply grid grid-cols-5 h-50;
    }

    .block {
        @apply flex flex-col justify-center items-center;
    }
}
</style>  